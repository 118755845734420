import { useMutation } from '@tanstack/react-query';
import fetchClient from '../rest/fetch-client';

export type CreateFirebaseTokenRequest = {
  fcm_token: string;
};
export const useCreateFirebaseToken = () => {
  const { mutate: createFirebaseToken, ...rest } = useMutation({
    mutationFn: async (request: CreateFirebaseTokenRequest) => {
      const token = localStorage.getItem('authToken');
      const response = await fetchClient.post(
        `${fetchClient.defaults.baseURL}fcm-token/`,
        request,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    },
    onSuccess: (data) => {},
  });

  return { createFirebaseToken, ...rest };
};
