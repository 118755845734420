// src/api/rest/fetch-client.ts
import axios from 'axios';
import { LoginGoogleResponse, LoginResponse, PasswordRecoverResponse, PasswordResetResponse, RegisterResponse } from '../../context/AuthContext';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

const fetchClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  },
});

fetchClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get = async (url: string, config?: any) => {
  const response = await fetchClient.get(url, config);
  return response.data;
};

export const post = async (url: string, data: any, config?: any) => {
  const response = await fetchClient.post(url, data, config);
  return response.data;
};

export const put = async (url: string, data: any, config?: any) => {
  const response = await fetchClient.put(url, data, config);
  return response.data;
};

export const loginRequest = async (
  username: string,
  password: string
): Promise<LoginResponse> => {
  try {
    const response = await fetchClient.post(
      `${fetchClient.defaults.baseURL}/api/token/`,
      {
        username,
        password,
      }
    );
    return response.data as LoginResponse; // Assuming response.data contains { token: string }
  } catch (error) {
    throw new Error('Login failed'); // Customize the error handling as required
  }
};

export const loginGoogleRequest = async (idToken: string): Promise<LoginGoogleResponse> => {
  try {
    const response = await fetchClient.post(`${fetchClient.defaults.baseURL}/oauth2callback/`, {
      id_token: idToken, // Pass the id_token to the backend
    });
    return response.data as LoginGoogleResponse; // Assuming response.data contains { token: string }
  } catch (error) {
    throw new Error('Login failed'); // Customize the error handling as required
  }
};

export const registerRequest = async (
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  country: string | null,
  region: string | null,
  city: string | null
): Promise<RegisterResponse> => {
  try {
    const response = await fetchClient.post(
      `${fetchClient.defaults.baseURL}/users/`,
      {
        username: email,
        password,
        first_name: firstName,
        last_name: lastName,
        email,
        user_profile: {
          country,
          region,
          city,
        },
      }
    );
    return response.data as RegisterResponse;
  } catch (error) {
    throw new Error('Registration failed'); // Customize the error handling as required
  }
};


export const passwordRecoverRequest = async (
  email: string,
): Promise<PasswordRecoverResponse> => {
  try {
    const response = await fetchClient.post(
      `${fetchClient.defaults.baseURL}/password-reset/`,
      {
        email: email,
      }
    );
    return response.data as PasswordRecoverResponse;
  } catch (error) {
    throw new Error('Registration failed'); // Customize the error handling as required
  }
};

export const passwordResetRequest = async (
  new_password: string,
  id: string | undefined,
  token: string | undefined
): Promise<PasswordResetResponse> => {
  try {
    const response = await fetchClient.post(
      `${fetchClient.defaults.baseURL}/password-reset-confirm/${id}/${token}/`,
      {
        new_password: new_password,
      }
    );
    return response.data as PasswordRecoverResponse;
  } catch (error) {
    throw new Error('Registration failed'); // Customize the error handling as required
  }
};

// You can also define other HTTP methods (PUT, DELETE, etc.) if needed

export default fetchClient;
