import {
  Box,
  Button,
  FormControlLabel,
  SelectChangeEvent,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { LogoutIcon } from 'components/animatedIcons/logout';
import CustomSelect from 'components/general/CustomSelect/CustomSelect';
import { useAuth } from 'context/AuthContext';
import { useThemeContext } from 'context/ThemeContext';
import { useTranslation } from 'context/TranslationContext';
import { useNavigate } from 'react-router-dom';

export const SettingsSection = () => {
  const theme = useTheme();
  const { toggleTheme, isLightTheme } = useThemeContext();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { t, setLanguage, language } = useTranslation();

  const handleLogout = () => {
    logout();
    navigate(`/login`);
  };

  return (
    <Box display="flex" flexDirection="column" gap={4} padding={2}>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border.primary}`,
          borderRadius: '16px',
          padding: 2,
        }}
      >
        <Typography variant="h6">{t('settings.theme')}</Typography>
        <Typography variant="body2">
          {t('settings.theme_description')}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={!isLightTheme}
              onChange={toggleTheme}
              color="primary"
            />
          }
          label={
            isLightTheme ? t('settings.light_mode') : t('settings.dark_mode')
          }
          sx={{ mt: 1 }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border.primary}`,
          borderRadius: '16px',
          padding: 2,
        }}
      >
        <Typography variant="h6">{t('settings.language_selector')}</Typography>
        <Typography variant="body2">
          {t('settings.language_description')}
        </Typography>
        <CustomSelect
          label={t('settings.language')}
          value={language ?? 'en'}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setLanguage(e.target.value as string);
          }}
          sx={{ width: '200px', gap: 1, mt: 1 }}
          options={[
            { value: 'en', label: t('settings.languages.english') },
            { value: 'pt', label: t('settings.languages.portuguese') },
            { value: 'fr', label: t('settings.languages.french') },
            { value: 'ar', label: t('settings.languages.arabic') },
            { value: 'it', label: t('settings.languages.italian') },
            { value: 'es', label: t('settings.languages.spanish') },
          ]}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border.primary}`,
          borderRadius: '16px',
          padding: 2,
        }}
      >
        <Typography variant="h6">{t('settings.logout')}</Typography>
        <Typography variant="body2">
          {t('settings.logout_description')}
        </Typography>
        <Button
          onClick={handleLogout}
          variant="outlined"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 1,
            width: 'fit-content',
            borderRadius: '24px',
          }}
        >
          <LogoutIcon />
          <Typography>{t('settings.logout')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};
