import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Pagination,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  FiltersConfiguration,
  useGetFiltersConfigurations,
} from 'api/hooks/useConfigurations';
import { EarthIcon } from 'components/animatedIcons/earth';
import { OrderIcon } from 'components/animatedIcons/order';
import { SquareStackIcon } from 'components/animatedIcons/square-stack';
import CustomSelect from 'components/general/CustomSelect/CustomSelect';
import { IconButton } from 'components/general/IconButton/IconButton';
import { MapContainer } from 'components/general/Map/Map';
import { useThemeContext } from 'context/ThemeContext';
import { useTranslation } from 'context/TranslationContext';
import { AnimatePresence, motion } from 'framer-motion';
import { FiltersChips } from 'pages/ListingPage/FiltersChips';
import React, { ReactNode, useEffect, useState } from 'react';
import { Listing, useListing } from '../../api/hooks/useListing';
import Filters from '../../components/Filters/Filters';
import { useFilterContext } from '../../context/FilterContext';
import { AddListing } from './AddListing';
import ListingsContainer from './Listings/Listings';

const ListingPage = ({ isBackOffice = false }: { isBackOffice?: boolean }) => {
  const theme = useTheme();
  const {
    query,
    orderBy,
    condition,
    propertyType,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    bedroomFilter,
    bathroomFilter,
    marketplaces,
    listing_type,
    locations,
    amenities,
    updatedAt,
    populateFiltersFromObj,
    resetFilters,
  } = useFilterContext();

  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [showMapView, setShowMapView] = useState(false);
  const [totalCountCache, setTotalCountCache] = useState<number>(0);
  const {
    results,
    isFetching: isLoading,
    totalCount,
    refetch,
  } = useListing({
    query,
    isBackOffice,
    locations,
    orderBy,
    condition,
    propertyType,
    listing_type,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    bedrooms: bedroomFilter,
    bathrooms: bathroomFilter,
    marketplaces,
    amenities,
    updatedAt,
    page,
    pageSize: !showMapView ? 20 : totalCountCache,
  });

  useEffect(() => {
    refetch();
  }, [isBackOffice]);

  useEffect(() => {
    if (totalCount !== 0 && totalCount !== totalCountCache) {
      setTotalCountCache(totalCount);
    }
  }, [totalCount]);

  const { filtersConfigurations } = useGetFiltersConfigurations();
  const [selectedView, setSelectedView] = useState<string | ''>('');
  const handleViewChange = (str: string) => {
    setSelectedView(str);
    const filtersConfiguration = filtersConfigurations.find(
      (filtersConfiguration) => filtersConfiguration.name === str
    );
    if (filtersConfiguration) {
      resetFilters();
      populateFiltersFromObj(filtersConfiguration.parameters.filters);
    }
  };

  const viewMode = (
    <>
      <IconButton
        hasBorder={false}
        onClick={() => {
          setShowMapView(true);
        }}
        sx={{ color: theme.palette.icon.primary }}
      >
        <EarthIcon />
      </IconButton>
      <IconButton
        hasBorder={false}
        onClick={() => {
          setShowMapView(false);
        }}
        sx={{ color: theme.palette.icon.primary }}
      >
        <SquareStackIcon />
      </IconButton>
    </>
  );

  const actionsBar = (
    <Box
      sx={{
        borderRadius: 4,
        background: theme.palette.gradient.backgroundGradient,
        display: 'flex',
        marginTop: 2,
        marginX: isMobile ? 2 : 0,
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      {' '}
      <AddListing />{' '}
      <IconButton
        hasBorder={false}
        onClick={() => {}}
        sx={{ color: theme.palette.icon.primary }}
      >
        {' '}
        <OrderIcon />
      </IconButton>
      {isMobile && (
        <Box
          onClick={() => setFiltersVisible(false)}
          sx={{ paddingX: 3, marginTop: 1 }}
        >
          <TuneIcon
            onClick={(e) => {
              setFiltersVisible(!filtersVisible);
              e.stopPropagation();
            }}
          />
        </Box>
      )}
      {viewMode}
    </Box>
  );

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 5fr',
        gap: isMobile ? 1 : 0,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {isMobile && actionsBar}

      <FiltersContainer
        actionsBar={actionsBar}
        mobileViewIsOpen={filtersVisible}
        mobileViewHandler={() => setFiltersVisible(false)}
        selectedView={selectedView}
        handleViewChange={handleViewChange}
        filtersConfigurations={filtersConfigurations}
        resetResults={() => {}}
      />

      <ExploreContainer
        setPage={setPage}
        showMapView={showMapView}
        isLoading={isLoading}
        isBackOffice={isBackOffice}
        results={results}
        totalCountCache={totalCountCache}
        resetResults={() => {}}
      />
    </Box>
  );
};

const FiltersContainer = ({
  actionsBar,
  mobileViewIsOpen = false,
  mobileViewHandler,
  selectedView,
  handleViewChange,
  filtersConfigurations,
  resetResults,
}: {
  actionsBar: ReactNode;
  mobileViewIsOpen?: boolean;
  mobileViewHandler: () => void;
  selectedView: string;
  handleViewChange: (str: string) => void;
  filtersConfigurations: FiltersConfiguration[];
  resetResults: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLightTheme } = useThemeContext();

  const boxShadow = !isLightTheme
    ? `6px -1px 6px 0px #24504f `
    : `6px -1px 6px 0px #c0cbd3 `;
  return isMobile ? (
    <AnimatePresence>
      {mobileViewIsOpen && (
        <motion.div
          drag="y"
          style={{
            width: '-webkit-fill-available',
            position: 'fixed',
            height: 'calc(100% - 6rem)',
            bottom: 0,
            left: 0,
            zIndex: 10,
          }}
          dragConstraints={{ top: 0, bottom: 0 }}
          onDragEnd={(_, info) => {
            if (info.offset.y > 300) mobileViewHandler();
          }}
        >
          <motion.div
            exit={{ opacity: 0.5, y: '110%' }}
            initial={{ opacity: 0.5, y: '110%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ damping: 25, type: 'spring' }}
          >
            <Stack
              sx={{
                background: theme.palette.background.default,
                boxShadow: boxShadow,
                borderRadius: '16px',
              }}
            >
              <Box
                sx={{ height: '24px', width: '-webkit-fill-available' }}
              ></Box>
              <Stack
                sx={{
                  height: 'calc(100vh - 115px)',
                  padding: '2em',
                  paddingBottom: '400px',

                  overflow: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none', // Hides the scrollbar
                  },
                }}
              >
                <CustomSelect
                  value={selectedView}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    handleViewChange(e.target.value as string);
                  }}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  label={t('listing.view')}
                  options={filtersConfigurations.map(
                    (filtersConfiguration) => ({
                      value: filtersConfiguration.name,
                      label: filtersConfiguration.name,
                    })
                  )}
                />
                <Filters resetResults={resetResults} />
              </Stack>
            </Stack>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        paddingLeft: 3,
        paddingRight: 3,
        gap: 3,
        maxHeight: '100vh',
        overflowY: 'auto',
        width: isMobile ? 'auto' : 'min-content',
        boxShadow: `0 4px 14px -4px ${theme.palette.primary.main}`,
      }}
    >
      {actionsBar}

      <CustomSelect
        value={selectedView}
        onChange={(e: SelectChangeEvent<unknown>) => {
          handleViewChange(e.target.value as string);
        }}
        fullWidth
        sx={{ marginBottom: 2 }}
        label={'View'}
        options={filtersConfigurations.map((filtersConfiguration) => ({
          value: filtersConfiguration.name,
          label: filtersConfiguration.name,
        }))}
      />
      <Filters resetResults={resetResults} />
    </Stack>
  );
};

const ExploreContainer = ({
  showMapView,
  isBackOffice,
  isLoading,
  results,
  totalCountCache,
  resetResults,
  setPage,
}: {
  showMapView: boolean;
  isBackOffice: boolean;
  isLoading: boolean;
  results: Listing[];
  totalCountCache: number;
  resetResults: () => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack>
      <Stack
        sx={{
          display: 'flex',
          paddingBottom: isMobile ? 2 : 4,
          paddingRight: 4,
          paddingLeft: 2,
          marginTop: isMobile ? 2 : 0,
          boxShadow: `0 1px 16px -12px ${theme.palette.primary.main}`,
        }}
      >
        {!isMobile && <FiltersChips resetResults={resetResults} />}
        <Box
          sx={{
            display: isMobile ? 'grid' : 'flex',
            gridTemplateColumns: '1fr',
            alignItems: 'center',
            width: '100%',

            gap: isMobile ? 2 : 1,
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <Pagination
            onChange={(event: React.ChangeEvent<unknown>, page: number) =>
              setPage(page)
            }
            count={Math.ceil(totalCountCache / 20)}
            variant="outlined"
            color="primary"
          />
          <Typography variant="body2" sx={{ minWidth: 'max-content' }}>
            {totalCountCache} matches
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          height: 'calc(100vh - 115px)',
          paddingBottom: '16px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}
      >
        {showMapView ? (
          <MapContainer
            id="map"
            center={{
              lat: 41.5454,
              lng: -8.4265,
            }}
            enableCard={true}
            listings={results} // Pass the array of listings here
            sx={{ margin: '12px', height: '80vh' }}
          />
        ) : (
          <ListingsContainer
            isLoading={isLoading}
            results={results}
            isBackOffice={isBackOffice}
            resetResults={resetResults}
          />
        )}
      </Box>
    </Stack>
  );
};

export default ListingPage;
