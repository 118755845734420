// App.tsx
import { getToken, onMessage } from '@firebase/messaging';
import { Box, GlobalStyles, useMediaQuery, useTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useCreateFirebaseToken } from 'api/hooks/useFirebase';
import { backgroundImage } from 'components/utils/utils';
import { Notifications } from 'pages/Notifications/Notifications';
import Overview from 'pages/Overview/Overview';
import { Subscription } from 'pages/Subscription/Subscription';
import { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { AuthProvider, useAuth } from './context/AuthContext';
import { FilterProvider } from './context/FilterContext';
import { ListingDetailsProvider } from './context/ListingDetailsContext';
import { SnackbarProvider, useSnackbar } from './context/SnackbarContext';
import { ThemeProvider } from './context/ThemeContext';
import { TranslationProvider } from './context/TranslationContext';
import { messaging } from './firebase';
import ListingDetails from './pages/ListingDetails/ListingDetails';
import ListingPage from './pages/ListingPage/ListingPage';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <TranslationProvider>
          <SnackbarProvider>
            <AuthProvider>
              <AppContent />
            </AuthProvider>
          </SnackbarProvider>
        </TranslationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const AppContent = () => {
  const location = useLocation();
  const showNavbar =
    location.pathname !== '/login' && location.pathname !== '/';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { showMessage } = useSnackbar();
  const { createFirebaseToken } = useCreateFirebaseToken();
  const { userProfile } = useAuth();
  const subscribeToFirebase = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        if (
          'serviceWorker' in navigator &&
          navigator.serviceWorker.controller
        ) {
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.FIREBASE_KEY,
          });
          if (currentToken) {
            console.log('FCM Token:', currentToken);
            const response = await createFirebaseToken({
              fcm_token: currentToken,
            });
            return 'success';
          } else {
            console.log('No FCM token available');
          }
        } else {
          console.log('Service Worker is not available');
        }
      } else {
        console.log('Permission for notifications was denied', 'error');
      }
    } catch (error) {
      console.error('Error during Firebase subscription:', error);
    }
    return 'failure'; // Return failure if something goes wrong
  };
  useEffect(() => {
    if (!!userProfile) {
      const subscribe = async () => {
        try {
          const result = await subscribeToFirebase();
          if (result === 'success') {
            console.log('Successfully subscribed to Firebase');
          } else {
            console.log('Failed to subscribe to Firebase');
          }
        } catch (error) {
          console.error('Error subscribing to Firebase:', error);
        }
      };
      subscribe();
    }
  }, [userProfile]);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      showMessage('payload', 'info');
    });
  }, []);

  return (
    <>
      <GlobalStyles
        styles={{
          /* Light Mode Scrollbar */
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.background.default, // Light background
          },
          '::-webkit-scrollbar-thumb': {
            cursor: 'pointer',
            backgroundColor: '#dde6ea', // Primary color
            borderRadius: '6px',
            border: `3px solid ${theme.palette.background.default}`, // Main background color
          },
          '::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.action.selected, // Secondary color on hover
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          height: '100vh',
        }}
      >
        {!isMobile && showNavbar && (
          <Navbar
            onClick={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}

        <Box
          sx={{
            flexGrow: 1,
            overflowY: isMobile ? ' hidden' : 'auto',
            overflowX: 'hidden',
            height: isMobile ? '90vh' : '100vh',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            ...backgroundImage,
          }}
        >
          <AuthRoutes />
        </Box>

        {isMobile && showNavbar && (
          <Navbar
            onClick={() => {
              throw new Error('Function not implemented.');
            }}
          />
        )}
      </Box>
    </>
  );
};
const AuthRoutes = () => {
  const { isAuthenticated, userProfile, isLoadingUserProfile } = useAuth();
  const isStaff = userProfile?.is_staff;
  const location = useLocation();
  if (isLoadingUserProfile) {
    return <div>Loading...</div>; // Show a loading indicator here
  }

  const { id } = useParams();

  return (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? '/listing' : '/login'} replace />
        }
      />
      <Route path="/login" element={<Login key={location.pathname} />} />
      <Route
        path="/profile"
        element={
          isAuthenticated ? (
            <Profile key={location.pathname} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/notifications"
        element={
          isAuthenticated ? (
            <Notifications key={location.pathname} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/overview"
        element={
          isAuthenticated ? (
            <Overview key={location.pathname} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/listing"
        element={
          isAuthenticated ? (
            <FilterProvider>
              <ListingPage key={location.pathname} />
            </FilterProvider>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/backoffice"
        element={
          isAuthenticated && isStaff ? (
            <FilterProvider>
              <ListingPage key={location.pathname} isBackOffice={true} />
            </FilterProvider>
          ) : (
            <Navigate to="/listing" replace />
          )
        }
      />
      <Route
        path="/subscription"
        element={
          isAuthenticated ? (
            <Subscription key={location.pathname} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/listing/:id"
        element={
          isAuthenticated ? (
            <ListingDetailsProvider>
              <ListingDetails key={`${location.pathname}-${id}`} />
            </ListingDetailsProvider>
          ) : (
            <Navigate to="/login" replace state={{ from: location }} />
          )
        }
      />
      <Route path="/login/:id/:token" element={<Login />} />
    </Routes>
  );
};

export default App;
