import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import CustomTextField from 'components/general/CustomTextField/CustomTextField';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'context/TranslationContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { commonColors } from 'theme/theme';

type RegisterFormState = {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  region: string;
  city: string;
  password: string;
};

// Shared text field styles
const textFieldStyles: SxProps = {
  '& .MuiFormLabel-root': { fontSize: '14px', color: commonColors.darkGray },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '32px',
    height: '50px',
    '& fieldset': { borderColor: commonColors.windyBlue },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: commonColors.primaryBlue,
    },
  },
};

const RegisterForm = () => {
  const [formState, setFormState] = useState<RegisterFormState>({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    region: '',
    city: '',
    password: '',
  });

  const navigate = useNavigate();
  const { register } = useAuth();
  const { t } = useTranslation();
  const [passwordError, setPasswordError] = useState('');

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle registration submission
  const handleSubmitRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    const { firstName, lastName, email, password } = formState;

    if (!firstName || !lastName || !email || !password) {
      setPasswordError(t('register.fill_required_error'));
      return;
    }

    try {
      await register(formState);
      navigate('/listing');
    } catch (error) {
      setPasswordError(t('register.failure_message'));
      console.error('Registration error:', error);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmitRegister}
      sx={{
        padding: '2rem',
        maxWidth: '500px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      gap={4}
    >
      <Typography variant="h3" color={commonColors.primaryBlue}>
        {t('register.title')}
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          maxHeight: '60vh', // Sets maximum height for form fields
          overflowY: 'auto', // Enables vertical scrolling
          overflowX: 'hidden',
          '&::-webkit-scrollbar': { display: 'none' },
          mb: 2,
        }}
      >
        {(Object.keys(formState) as Array<keyof RegisterFormState>).map(
          (field) => (
            <CustomTextField
              key={field}
              label={t(`register.${field}_label`)}
              name={field}
              variant="outlined"
              value={formState[field]}
              onChange={handleInputChange}
              required={['firstName', 'lastName', 'email', 'password'].includes(
                field
              )}
              type={field === 'password' ? 'password' : 'text'}
              fullWidth
              margin="normal"
              errorMessage={field === 'password' ? passwordError : ''}
              sx={textFieldStyles}
            />
          )
        )}
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          borderRadius: 36,
          fontWeight: 'bold',
          height: '48px',
        }}
      >
        {t('register.submit_button')}
      </Button>
    </Stack>
  );
};

export default RegisterForm;
