import {
  alpha,
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useStats } from 'api/hooks/useStats';
import { LineChart } from 'components/general/LineChart/LineChart';
import { formatTime } from 'components/utils/utils';
import { useAuth } from 'context/AuthContext';

import CloseIcon from '@mui/icons-material/Close';
import { Tab, Tabs } from '@mui/material';
import { AnimatedBox } from 'components/general/GradientContainer/AnimatedBox';
import { useTranslation } from 'context/TranslationContext';
import React, { useState } from 'react';

type CityData = {
  [city: string]: number[];
};

type LineChart = {
  label: string;
  keys: string[];
  datasets: {
    values: number[];
    color: string;
    datasetName: string;
  }[];
};

const itemsAddedByCity: CityData = {
  Braga: [12, 32, 42, 12, 32, 13, 32, 23, 42, 12, 32, 13],
  Porto: [1, 52, 12, 16, 37, 3, 32, 9, 52, 12, 16, 37],
  Viana: [21, 45, 67, 89, 23, 45, 67, 78, 90, 12, 34, 56],
  Lisboa: [11, 24, 36, 49, 58, 69, 72, 85, 93, 17, 29, 31],
};

const Overview = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { stats } = useStats();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getDayLabel = (daysAgo: number): string => {
    const today = new Date();
    today.setDate(today.getDate() - daysAgo);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    return today.toLocaleDateString('en-US', options);
  };
  const keys: string[] = Array.from({ length: 12 }, (_, i) => getDayLabel(i));

  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const datasets = Object.entries(itemsAddedByCity).map(
    ([city, values], index) => ({
      values,
      color: getRandomColor(),
      datasetName: city,
    })
  );

  const charts = [
    {
      label: t('overview.by_location'),
      keys: keys,
      datasets: datasets,
    },
  ];

  return (
    <Box p={6}>
      <Greetings />
      <Typography variant="h4" gutterBottom>
        {t('overview.title')}
      </Typography>

      <Typography variant="h6" gutterBottom>
        {t('overview.daily_income_deals')}
      </Typography>
      <AnimatedBox
        sx={{ width: 'inherit', height: 'auto' }}
        isGradient={false}
        hasBoxShadow={false}
        hasBorder={false}
      >
        <Stack sx={{ margin: 4 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {charts.map((chart, index) => (
              <Tab
                sx={{
                  color:
                    activeTab === 0
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled,
                  textTransform: 'none', // Prevent text from being in caps
                }}
                key={index}
                label={chart.label}
              />
            ))}
          </Tabs>
          <LineChartContainer chart={charts[activeTab]} />
        </Stack>
      </AnimatedBox>
    </Box>
  );
};

export const LineChartContainer = ({ chart }: { chart: LineChart }) => {
  const [datasetKey, setDatasetKey] = React.useState<string[]>([]);
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<typeof datasetKey>) => {
    const {
      target: { value },
    } = event;
    setDatasetKey(typeof value === 'string' ? value.split(',') : value);
  };

  const removeDatasetKey = (keyToRemove: string) => {
    setDatasetKey((prevState) =>
      prevState.filter((key) => key !== keyToRemove)
    );
  };

  return (
    <Stack mt={4} sx={{ display: 'flex' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
          marginLeft: 4,
        }}
      >
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel>{t('overview.location')}</InputLabel>
          <Select
            label={t('overview.location')}
            placeholder={t('overview.location')}
            multiple
            value={datasetKey}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  width: 250,
                  borderRadius: 16,
                },
              },
            }}
            sx={{
              width: 250,
              borderRadius: 16,
            }}
          >
            {Object.keys(itemsAddedByCity).map((datasetKey) => (
              <MenuItem key={datasetKey} value={datasetKey}>
                {datasetKey}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          {datasetKey.map((datasetKeyName, index) => {
            const color = chart.datasets.find(
              (dataset) => dataset.datasetName === datasetKeyName
            )?.color;
            const rgbcolor = alpha(color ?? '', 0.24);
            return (
              <Chip
                variant="outlined"
                key={datasetKeyName}
                sx={{
                  color: color,
                  borderColor: color,
                  margin: 1,
                  background: `linear-gradient(to bottom, ${rgbcolor} 1%, rgba(18, 211, 171, 0) 35%)`,
                  cursor: 'pointer',
                  opacity: 0,
                  transform: 'scale(0.9)',
                  animation: `fadeIn 0.3s forwards`,
                  animationDelay: `${index * 0.1}s`,
                  '@keyframes fadeIn': {
                    to: {
                      opacity: 1,
                      transform: 'scale(1)',
                    },
                  },
                }}
                label={datasetKeyName}
                deleteIcon={
                  <IconButton>
                    <CloseIcon sx={{ color: color }} />
                  </IconButton>
                }
                onDelete={() => removeDatasetKey(datasetKeyName)}
              />
            );
          })}
        </Box>
      </Box>
      <LineChart
        width={700}
        height={400}
        keys={chart.keys}
        datasets={chart.datasets.filter((dataset) =>
          datasetKey.includes(dataset.datasetName)
        )}
      />
    </Stack>
  );
};

export const Greetings = () => {
  const { userProfile } = useAuth();
  const { t } = useTranslation();

  const greetingMessage = () => {
    const today = new Date();
    const hours = today.getHours();
    if (hours >= 5 && hours < 12) {
      return t('overview.good_morning');
    }
    if (hours >= 12 && hours < 19) {
      return t('overview.good_afternoon');
    }
    return t('overview.good_evening');
  };

  const name = userProfile?.first_name
    ? userProfile?.first_name
    : userProfile?.username;
  return (
    <Stack>
      <Typography variant="subtitle1">{formatTime(new Date())}</Typography>
      <Typography variant="h2" fontSize={'40px'}>
        {greetingMessage() + (', ' + name ?? '')}
      </Typography>
    </Stack>
  );
};

export default Overview;
