import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

interface CustomPhotoInputProps {
  initialPhoto?: File | null; // Accept undefined
  onChange: (file: File | null) => void;
}

const CustomPhotoInput = ({
  initialPhoto = null,
  onChange,
}: CustomPhotoInputProps) => {
  const [photo, setPhoto] = useState<File | null>(initialPhoto);

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setPhoto(file);
    onChange(file);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      width="160px"
      height="160px"
      border="1px dashed gray"
      borderRadius="50%"
      marginTop={2}
      position="relative"
    >
      {photo ? (
        <img
          src={URL.createObjectURL(photo)}
          alt="Profile"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      ) : (
        <Typography variant="body2" color="gray">
          No photo uploaded
        </Typography>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handlePhotoChange}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          cursor: 'pointer',
        }}
      />
    </Box>
  );
};

export default CustomPhotoInput;
