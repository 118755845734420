import DehazeIcon from '@mui/icons-material/Dehaze';
import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

interface Option {
  label: string;
  value: string;
}

interface CustomCheckboxProps {
  title: string;
  options: Option[];
  selectedValues: string[];
  onChange: (selected: string[]) => void;
}

export function CustomCheckbox({
  title,
  options,
  selectedValues,
  onChange,
}: CustomCheckboxProps) {
  const [showOptions, setShowOptions] = useState(true);
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedSelected = event.target.checked
      ? [...selectedValues, value]
      : selectedValues.filter((selected) => selected !== value);
    onChange(updatedSelected);
  };

  return (
    <FormControl
      component="fieldset"
      sx={{
        p: 2,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.border.primary}`,
      }}
    >
      <FormLabel
        onClick={() => setShowOptions(!showOptions)}
        sx={{
          cursor: 'pointer',
          color: theme.palette.text.primary,
        }}
      >
        <Box
          onClick={() => setShowOptions(!showOptions)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body1">
            {showOptions || selectedValues.length === 0
              ? title
              : `${title} (${selectedValues.length})`}
          </Typography>

          <IconButton sx={{ width: '28px', height: '28px' }}>
            <DehazeIcon
              sx={{
                fontSize: '1.2rem',
                transition: 'transform 0.7s ease',
                transform: showOptions ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
        </Box>
      </FormLabel>

      <FormGroup
        sx={{
          transition: 'max-height 1s ease, opacity 1s ease',
          maxHeight: showOptions ? '1000px' : '0px',
          opacity: showOptions ? 1 : 0,
          overflow: 'hidden',
        }}
      >
        <>
          <Fade in={showOptions} timeout={2000}>
            <Stack>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      value={option.value}
                      checked={selectedValues.includes(option.value)}
                      onChange={handleChange}
                      sx={{
                        color: theme.palette.primary.main,
                        '&.Mui-checked': {
                          color: theme.palette.primary.dark,
                        },
                        '& .MuiSvgIcon-root': {
                          backgroundColor: 'transparent',
                          transform: 'scale(0.8)',
                        },
                        paddingY: 0.5,
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        opacity: selectedValues.includes(option.value)
                          ? 1
                          : 0.7,
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            </Stack>
          </Fade>
        </>
      </FormGroup>
    </FormControl>
  );
}
