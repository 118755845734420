export function formatPrice(price: string) {
  const numericPrice = parseFloat(price);

  if (isNaN(numericPrice)) {
    return null;
    //throw new Error('Invalid price value');
  }

  const integerPrice = Math.floor(numericPrice).toString();

  const formattedPrice = integerPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return formattedPrice + ' €';
}

export function calculateDefinedPropertiesPercentage<
  T extends Record<string, any>,
>(obj: T): number {
  const checkProperties = (
    currentObj: any
  ): { total: number; defined: number } => {
    let total = 0;
    let defined = 0;

    for (const key in currentObj) {
      if (currentObj.hasOwnProperty(key)) {
        total++;
        if (
          currentObj[key] !== undefined &&
          currentObj[key] !== null &&
          currentObj[key] !== 'undefined' &&
          currentObj[key] !== 'unk' &&
          currentObj[key] !== 'UNK' &&
          currentObj[key] !== 'Unknown' &&
          currentObj[key] !== 'UNKNOWN'
        ) {
          defined++;
        }
        // If the property is an object, recursively check its properties
        if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
          const result = checkProperties(currentObj[key]);
          total += result.total;
          defined += result.defined;
        }
      }
    }
    return { total, defined };
  };

  const { total, defined } = checkProperties(obj);

  return total > 0 ? (defined / total) * 100 : 0;
}

export function hexToRgba(hex: string, alpha: any) {
  // Remove the hash symbol if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const backgroundImage = {
  backgroundImage: `
url(/hive_topcorner.svg), 
url(/hive_bottomcorner.svg)`,
  backgroundPosition: 'top right, bottom left',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: '385px 284px, 287px 226px',
};

export const invisibleScroll = {
  overflowY: 'scroll',
  height: '100vh',
  scrollbarWidth: 'none', // Firefox
  msOverflowStyle: 'none', // IE and Edge

  '&::-webkit-scrollbar': {
    // Chrome, Safari, and Opera
    display: 'none',
  },
};

export function getListingTypeColor(listingType?: string) {
  switch (listingType) {
    case 'SALE':
      return '#22e1dc';
    case 'RENT':
      return '#22e1ab';
    case 'AUCT':
      return '#e2a422';
    case 'LSE':
      return '#e122a8';
    case 'EXC':
      return '#dbe123';
    default:
      return '#22e1dc';
  }
}

export function formatTime(date: Date) {
  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
    .formatToParts(date)
    .map(({ type, value }) => {
      switch (type) {
        case 'literal':
          if (value === '/') {
            return `.`;
          }
          return value;

        default:
          return value;
      }
    })
    .join('');
}
