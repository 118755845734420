import { Button, SxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const IconButton = ({
  onClick,
  children,
  hasBorder = true,
  sx,
}: {
  onClick: () => void;
  children?: ReactNode;
  hasBorder?: boolean;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      sx={{
        borderRadius: '50%',
        border: hasBorder ? `2px solid ${theme.palette.icon.primary}` : 'none',
        padding: '8px',
        minWidth: '0',
        backgroundColor: theme.palette.gradient.backgroundGradient,
        color: theme.palette.icon.primary,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
