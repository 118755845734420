import { Stack, SxProps, useTheme } from '@mui/material';
import { useThemeContext } from 'context/ThemeContext';
import { ReactNode } from 'react';

export const GradientContainer = ({
  onClick,
  children,
  sx,
}: {
  onClick?: () => void;
  children: ReactNode;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Stack
      onClick={onClick}
      sx={{
        borderRadius: 4,
        background: theme.palette.gradient.backgroundGradient,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export const OptionalGradientContainer = ({
  isGradient,
  onClick,
  children,
  sx,
}: {
  isGradient?: boolean;
  onClick?: () => void;
  children: ReactNode;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const { isLightTheme } = useThemeContext();
  const isGradientResult = isGradient ? isGradient : !isLightTheme;
  return (
    <Stack
      onClick={onClick}
      sx={{
        borderRadius: isGradientResult ? 4 : 2,

        border: isGradientResult
          ? 'none'
          : `1px solid ${theme.palette.border.primary}`,
        background: isGradientResult
          ? theme.palette.gradient.backgroundGradient
          : theme.palette.background.paper,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
