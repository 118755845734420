import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  TextField,
  useTheme,
} from '@mui/material';
import {
  AdministrativeDivision,
  City,
  Country,
  District,
  Region,
} from 'api/hooks/useFilters';
import { useFilterContext } from 'context/FilterContext';

var match = require('autosuggest-highlight/match');
var parse = require('autosuggest-highlight/parse');

export interface Location {
  id: number;
  name: string;
  depth: number;
  parentId: number | null;
  children: Location[];
  matchTerms: string[];
}

const hasRegions = (division: AdministrativeDivision): division is Country =>
  (division as Country).regions !== undefined;

const hasDistricts = (division: AdministrativeDivision): division is Region =>
  (division as Region).districts !== undefined;

const hasCities = (division: AdministrativeDivision): division is District =>
  (division as District).cities !== undefined;

const hasParishes = (division: AdministrativeDivision): division is City =>
  (division as City).parishes !== undefined;

const toOptions = (
  division: AdministrativeDivision,
  depth: number = 0,
  parentId: number | null = null
): Location[] => {
  if (!division) return [];

  const { id, name } = division;

  const children = hasRegions(division)
    ? division.regions.flatMap((region) => toOptions(region, depth + 1, id))
    : hasDistricts(division)
      ? division.districts.flatMap((district) =>
          toOptions(district, depth + 1, id)
        )
      : hasCities(division)
        ? division.cities.flatMap((city) => toOptions(city, depth + 1, id))
        : hasParishes(division)
          ? division.parishes.flatMap((parish) =>
              toOptions(parish, depth + 1, id)
            )
          : [];

  const option: Location = {
    id,
    name,
    depth,
    parentId,
    children,
    matchTerms: [name].concat(children.map((child) => child.name)),
  };

  return [option, ...children];
};

// CustomAutoComplete component
export const CustomAutoComplete = ({
  districts,
  resetFilters,
}: {
  districts?: District[];
  resetFilters?: () => void;
}) => {
  const theme = useTheme();

  const { locations, setLocations } = useFilterContext();

  const optionsList: Location[] =
    districts?.map((district) => toOptions(district)).flat() ?? [];

  const handleChange = (value: Location) => {
    let updatedOptionsResult: Location[] = [];
    //insert
    if (
      !locations.find(
        (option) => option.id === value.id && option.depth === value.depth
      )
    ) {
      updatedOptionsResult.push(...locations);
      updatedOptionsResult.push(value);
      updatedOptionsResult.push(...value.children);
    }
    //delete
    else {
      updatedOptionsResult = locations.filter(
        (option) =>
          (option.depth === value.depth && option.id !== value.id) ||
          (option.depth - 1 === value.depth && option.parentId !== value.id) ||
          (option.depth + 1 === value.depth && option.id !== value.parentId)
      );
    }

    setLocations(updatedOptionsResult);
  };

  return (
    <Autocomplete
      multiple
      options={optionsList}
      getOptionLabel={(option) => option.name}
      value={locations}
      sx={{
        maxHeight: 200,
        mt: 1,
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: '8px !important',
        '& .MuiOutlinedInput-root': {
          height: ' 48px',
          borderRadius: '8px !important',
          '& fieldset': {
            //borderColor: theme.palette.action.selected + '!important',
            border: 'none',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          //borderColor: theme.palette.icon.primary,
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          //borderColor: theme.palette.action.selected,
          border: 'none',
        },
        '&.MuiOutlinedInput-notchedOutline': {
          //borderColor: theme.palette.action.selected,
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //borderWidth: '2px',
          //borderColor: theme.palette.icon.primary,
          border: 'none',
        },
        '& .MuiSelect-icon': {
          color: theme.palette.icon.primary,
        },
        '& .MuiMenuItem-root': {
          fontSize: '0.65rem', // Smaller font size for menu items
        },
      }}
      onChange={(event, newValue, reason, details) => {
        if (reason === 'clear') {
          setLocations([]);
        } else if (details && details.option) {
          handleChange(details.option);
        }
        resetFilters && resetFilters();
      }}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);
        const isSelected = locations.some(
          (selectedOption) =>
            selectedOption.id === option.id &&
            selectedOption.depth === option.depth
        );

        return (
          <li {...props} key={option.id + option.depth}>
            <Checkbox
              checked={isSelected}
              sx={{ ml: 2 * option.depth }}
              value={option.id} // Ensure the checkbox is controlled
            />
            <div>
              {parts.map((part: any, partIndex: any) => (
                <span
                  key={`${props}-${partIndex}`}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select Location" />
      )}
      filterOptions={createFilterOptions({
        stringify: (option) => option.matchTerms.join('//'),
      })}
      renderTags={(selecteds: Location[], getTagProps) => {
        return (
          <span>{`${selecteds.filter((selected) => selected.children.length === 0)?.length} (s) selected`}</span>
        );
      }}
    />
  );
};
