import { Box, SxProps, useTheme } from '@mui/material';
import {
  GradientContainer,
  OptionalGradientContainer,
} from 'components/general/GradientContainer/GradientContainer';
import { useThemeContext } from 'context/ThemeContext';
import { ReactNode, useState } from 'react';
import './styles.css';

export const AnimatedBox = ({
  ref,
  isGradient = true,
  hasBoxShadow = false,
  hasBorder = false,
  sx,
  children,
  onClick,
}: {
  ref?: any;
  isGradient?: boolean;
  hasBoxShadow?: boolean;
  hasBorder?: boolean;
  sx?: SxProps;

  children: ReactNode;
  onClick?: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isLightTheme } = useThemeContext();
  const theme = useTheme();
  const boxShadow = !isLightTheme
    ? `6px -1px 6px 0px #24504f `
    : `6px -1px 6px 0px #c0cbd3 `;
  return (
    <Box
      ref={ref}
      onClick={onClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        boxShadow: hasBoxShadow && isHovered ? boxShadow : 'none',
        height: '400px',
        width: '400px',
        border: hasBorder ? '1px solid transparent' : 'none', // Keep border color transparent to see the gradient effect
        borderRadius: '12px',
        background: `linear-gradient(var(--angle), transparent, ${theme.palette.border.primary}) border-box`,
        animation: isHovered ? '5s rotate linear infinite' : 'none',
        ...sx,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          width: '100%',
          height: '100%',
          borderRadius: '12px',
        }}
      >
        {isGradient ? (
          <GradientContainer
            sx={{ width: '100%', height: '100%', borderRadius: '12px' }}
          >
            {children}
          </GradientContainer>
        ) : (
          <OptionalGradientContainer
            sx={{ width: '100%', height: '100%', borderRadius: '12px' }}
          >
            {children}
          </OptionalGradientContainer>
        )}
      </Box>
    </Box>
  );
};
