import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

interface CustomTextFieldProps extends Omit<TextFieldProps, 'variant'> {
  label: string;
  variant?: 'outlined' | 'filled' | 'standard';
  errorMessage?: string; // Optional prop to pass custom error message
  endIcon?: React.ReactNode; // Optional prop to pass an icon
}

const CustomTextField = ({
  label,
  variant = 'outlined',
  value,
  errorMessage,
  endIcon,
  ...props
}: CustomTextFieldProps) => {
  return (
    <TextField
      label={label}
      variant={variant}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      value={value}
      error={!!errorMessage}
      helperText={errorMessage || undefined}
      InputProps={{
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : undefined,
        autoComplete: 'new-password', // Additional option to prevent autofill
      }}
      {...props}
    />
  );
};

export default CustomTextField;
