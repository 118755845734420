import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import fetchClient from 'api/rest/fetch-client';
import CustomTextField from 'components/general/CustomTextField/CustomTextField';
import { ReactComponent as VisibleIcon } from 'components/icons/visible_icon.svg';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'context/TranslationContext';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { commonColors } from 'theme/theme';

const textFieldStyles: SxProps = {
  '& .MuiFormLabel-root': { fontSize: '14px', color: commonColors.darkGray },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '32px',
    height: '50px',
    '& fieldset': { borderColor: commonColors.windyBlue },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: commonColors.primaryBlue,
    },
  },
};

const LoginForm = ({
  handleIsRecovering,
}: {
  handleIsRecovering: () => void;
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const { login: loginUser, loginGoogle } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmitLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const redirectTo = location.state?.from || '/listing';

    try {
      await loginUser(username, password);
      const response = await fetchClient.get('/user/');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        setPasswordError(error.message);
      } else {
        setPasswordError(t('login.incorrect_credentials'));
      }
    } finally {
      navigate(redirectTo);
      setLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    const idToken = credentialResponse.credential;
    const redirectTo = '/listing';
    try {
      await loginGoogle(idToken);
      const response = await fetchClient.get('/user/');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    } finally {
      navigate(redirectTo);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmitLogin}
      sx={{ width: '100%', maxWidth: '400px', margin: 'auto', marginBottom: 4 }}
      gap={5}
    >
      <Typography variant="h3" color={commonColors.primaryBlue}>
        {t('login.title')}
      </Typography>

      <Stack spacing={2}>
        <Typography
          variant="body2"
          sx={{ fontSize: '16px' }}
          color={commonColors.primaryBlue}
        >
          {t('login.email_address')}
        </Typography>
        <CustomTextField
          id="userInput"
          label={t('login.username')}
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          fullWidth
          margin="normal"
          sx={textFieldStyles}
        />

        <Typography
          variant="body2"
          sx={{ fontSize: '16px' }}
          color={commonColors.primaryBlue}
        >
          {t('login.password_label')}
        </Typography>
        <CustomTextField
          endIcon={
            <VisibleIcon
              height="24px"
              onClick={() => setShowPassword(!showPassword)}
            />
          }
          label={t('login.password')}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          margin="normal"
          errorMessage={passwordError}
          sx={textFieldStyles}
        />

        <Typography
          variant="subtitle1"
          sx={{
            marginTop: 1,
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
          onClick={handleIsRecovering}
        >
          {t('login.forgot_password')}
        </Typography>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        sx={{
          borderRadius: 36,
          fontWeight: 'bold',
          height: '48px',
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          t('login.button.login')
        )}
      </Button>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
      >
        <Box
          sx={{
            marginY: 2,
            width: '100%',

            '& > div > div> div> div': {
              borderRadius: '32px',
              height: '48px',
            },
          }}
        >
          <GoogleLogin
            onSuccess={(x) => handleGoogleLoginSuccess(x)}
            onError={() => console.log('error google')}
          />
        </Box>
      </GoogleOAuthProvider>
    </Stack>
  );
};

export default LoginForm;
