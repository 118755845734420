import {
  Bed,
  House,
  LocationOn,
  Storefront,
  Straighten,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Listing, PropertyDetails } from 'api/hooks/useListing';
import { DeleteIcon } from 'components/animatedIcons/delete';
import { CircularProgressWithLabel } from 'components/general/CustomCircularProgress/CustomCircularProgress';
import { AnimatedBox } from 'components/general/GradientContainer/AnimatedBox';
import {
  calculateDefinedPropertiesPercentage,
  formatPrice,
} from 'components/utils/utils';
import NoMediabox from 'pages/ListingDetails/page-components/PropertyMedia/NoMediaBox';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ListingCardProps {
  handleExternalCardClick?: () => void;
  listing: Listing;
  isBackOffice: boolean;
  handleDeleteListing?: (id: string) => void;
  sx?: any;
}

const ListingCard = ({
  listing,
  isBackOffice,
  sx,
  handleExternalCardClick,
  handleDeleteListing,
}: ListingCardProps) => {
  const { id, product = {}, price = 0, title = '', media = [] } = listing;
  const {
    bedrooms = 0,
    sq_meters = 0,
    property_type,
  } = product as PropertyDetails;
  const imageUrl = media[0]?.external_url;

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/listing/${listing.id}`);
  };

  const renderDetail = (icon: React.ReactNode, detail: number | string) => (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography variant="body2" sx={{ fontSize: '0.875rem', margin: 1 }}>
        {detail}
      </Typography>
    </Box>
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AnimatedBox
      onClick={
        handleExternalCardClick ? handleExternalCardClick : handleCardClick
      }
      hasBoxShadow={true}
      hasBorder={true}
      sx={{
        maxWidth: 300,
        width: '300px',
        minHeight: 'fit-content',
        height: 'fit-content',
        borderRadius: '16px',
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        ...sx,
        '&:hover': {
          '.marketplace-info': {
            opacity: 1,
          },
        },
      }}
    >
      {imageUrl ? (
        <Box
          component="img"
          src={imageUrl}
          alt=""
          sx={{
            margin: 1,
            height: 190,
            width: 284,
            objectFit: 'cover',
            borderRadius: '6px',
          }}
        />
      ) : (
        <NoMediabox
          sx={{
            height: '182px',
            width: '190px',
            alignSelf: 'center',
            margin: 1,
            borderRadius: 4,
          }}
          sxGradient={{ background: 'none' }}
        />
      )}
      <Stack sx={{ padding: '0 16px', gap: 2, marginTop: 1, marginBottom: 2 }}>
        <Stack gap={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
            >
              {price ? formatPrice(price) : 'Price not available'}
            </Typography>
            <CircularProgressWithLabel
              value={calculateDefinedPropertiesPercentage(listing)}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {renderDetail(
              <House sx={{ fontSize: 16, marginRight: 0.5 }} />,
              property_type ?? '-'
            )}
            {renderDetail(
              <Bed sx={{ fontSize: 16, marginRight: 0.5 }} />,
              bedrooms ?? '-'
            )}
            {renderDetail(
              <Straighten sx={{ fontSize: 16, marginRight: 0.5 }} />,
              sq_meters ? `${Math.trunc(sq_meters)} m²` : 'Size not specified'
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Box display="flex" alignItems="center">
              <LocationOn sx={{ fontSize: 16, marginRight: 1 }} />
              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {listing.product.parish_name ?? 'No location retrieved'}
              </Typography>{' '}
            </Box>
            {isBackOffice && (
              <IconButton
                sx={{ bottom: 4 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteListing && handleDeleteListing(id.toString());
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Stack>

        {!isMobile && (
          <Stack gap={1}>
            <Box
              display="flex"
              alignItems="center"
              className="marketplace-info"
              sx={{
                opacity: 0,
                transition: 'opacity 0.3s ease',
              }}
            >
              <Storefront sx={{ fontSize: 16, marginRight: 1 }} />
              <Typography variant={'body2'} fontSize={'12px'}>
                {listing.marketplace_name}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </AnimatedBox>
  );
};

export default ListingCard;
