// src/context/AuthContext.tsx
import {
  useGetUserProfile,
  UserProfileApiResponse,
} from 'api/hooks/useProfileUser';
import { useSnackbar } from 'context/SnackbarContext';
import React, { createContext, useContext, useState } from 'react';
import {
  loginGoogleRequest,
  loginRequest,
  passwordRecoverRequest,
  passwordResetRequest,
  registerRequest,
} from '../api/rest/fetch-client';

export interface LoginResponse {
  access: string;
  refresh: string;
}

export interface LoginGoogleResponse {
  access_token: string;
  refresh_token: string;
}

export interface RegisterResponse {
  code: number;
}

export interface PasswordRecoverResponse {
  message: string;
}

export interface PasswordResetResponse {
  message: string;
}

type RegisterData = {
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string | null;
  region: string | null;
  city: string | null;
};

type PasswordRecoverData = {
  email: string;
};

type PasswordResetData = {
  password: string;
  new_password: string;
  id: string | undefined;
  token: string | undefined;
};

const AuthContext = createContext<
  | {
      isAuthenticated: boolean;
      isLoadingUserProfile: boolean;
      userProfile: UserProfileApiResponse | null;
      login: (email: string, password: string) => Promise<LoginResponse>;
      register: ({
        password,
        firstName,
        lastName,
        email,
        country,
        region,
        city,
      }: RegisterData) => Promise<RegisterResponse>;
      logout: () => void;
      loginGoogle: (idToken: string) => void;
      recoverPassword: ({
        email,
      }: PasswordRecoverData) => Promise<PasswordRecoverResponse>;
      resetPassword: ({
        password,
        new_password,
        id,
        token,
      }: PasswordResetData) => Promise<PasswordResetResponse>;
    }
  | undefined
>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('authToken')
  );

  const { showMessage } = useSnackbar();
  const {
    userProfile,
    isLoading: isLoadingUserProfile,
    error,
  } = useGetUserProfile({
    enabled: isAuthenticated,
  });

  const login = async (
    username: string,
    password: string
  ): Promise<LoginResponse> => {
    try {
      const response: LoginResponse = await loginRequest(username, password);
      localStorage.setItem('authToken', response.access);
      setIsAuthenticated(true);
      return response;
    } catch (error: any) {
      if (error.response) {
        const status = error.response.status;
        const errorMessage =
          error.response.data?.message || 'An unknown error occurred';

        if (status === 400) {
          showMessage(
            'Invalid credentials. Please check your username and password.',
            'error'
          );
          throw new Error(
            'Invalid credentials. Please check your username and password.'
          );
        } else if (status === 404) {
          showMessage(
            'User not found. Please register or check your email.',
            'error'
          );
          throw new Error(
            'User not found. Please register or check your email.'
          );
        } else if (status === 500) {
          showMessage(
            'Internal server error. Please try again later.',
            'error'
          );
          throw new Error('Internal server error. Please try again later.');
        } else {
          throw new Error(errorMessage);
        }
      } else {
        showMessage(
          'Network error. Please check your connection and try again.',
          'error'
        );
        throw new Error(
          'Network error. Please check your connection and try again.'
        );
      }
    }
  };

  const loginGoogle = async (idToken: string): Promise<LoginGoogleResponse> => {
    try {
      const response: LoginGoogleResponse = await loginGoogleRequest(idToken);
      localStorage.setItem('authToken', response.access_token);
      localStorage.setItem('refreshToken', response.refresh_token);
      setIsAuthenticated(true);
      return response;
    } catch (error: any) {
      if (error.response) {
        const status = error.response.status;
        const errorMessage =
          error.response.data?.message || 'An unknown error occurred';

        if (status === 400) {
          showMessage(
            'Invalid credentials. Please check your username and password.',
            'error'
          );
          throw new Error(
            'Invalid credentials. Please check your username and password.'
          );
        } else if (status === 404) {
          showMessage(
            'User not found. Please register or check your email.',
            'error'
          );
          throw new Error(
            'User not found. Please register or check your email.'
          );
        } else if (status === 500) {
          showMessage(
            'Internal server error. Please try again later.',
            'error'
          );
          throw new Error('Internal server error. Please try again later.');
        } else {
          throw new Error(errorMessage);
        }
      } else {
        showMessage(
          'Network error. Please check your connection and try again.',
          'error'
        );
        throw new Error(
          'Network error. Please check your connection and try again.'
        );
      }
    }
  };

  const register = async ({
    password,
    firstName,
    lastName,
    email,
    country,
    region,
    city,
  }: RegisterData): Promise<RegisterResponse> => {
    try {
      const response: RegisterResponse = await registerRequest(
        password,
        firstName,
        lastName,
        email,
        country,
        region,
        city
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        register,
        logout,
        loginGoogle,
        recoverPassword,
        resetPassword,
        userProfile,
        isLoadingUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const recoverPassword = async ({
  email,
}: PasswordRecoverData): Promise<PasswordRecoverResponse> => {
  try {
    const response: PasswordRecoverResponse =
      await passwordRecoverRequest(email);
    return response;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async ({
  new_password,
  id,
  token,
}: PasswordResetData): Promise<PasswordResetResponse> => {
  try {
    const response: PasswordResetResponse = await passwordResetRequest(
      new_password,
      id,
      token
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
