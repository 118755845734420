import {
  Box,
  Fade,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

interface ExpandableIconLabelProps {
  icon: React.ReactNode;
  label: string;
  color: string;
  endingWidth?: string;
  startingWidth?: string;
  iconPlacement?: 'start' | 'end' | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  sx?: SxProps;
}

const ExpandableIconLabel: React.FC<ExpandableIconLabelProps> = ({
  endingWidth,
  startingWidth,
  iconPlacement,
  icon,
  label,
  color,
  onClick,
  sx,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          paddingY: 0.5,
          paddingX: 1,
          border: `1px solid ${color}`,
          borderRadius: 8,
          color: color,
          ...sx,
        }}
      >
        {(!iconPlacement || iconPlacement === 'start') && icon}
        <Typography
          variant="subtitle1"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </Typography>
        {iconPlacement === 'end' && icon}
      </Box>
    );
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center',
        background: theme.palette.gradient.backgroundGradient,
        paddingY: 0.5,
        paddingX: 1,
        borderRadius: 8,
        color: color,
        width: isHovered ? (endingWidth ?? '60px') : (startingWidth ?? '24px'), // This defines the width when hovered and not hovered
        transition: 'width 1s ease', // Smooth transition for width change
        overflow: 'hidden',
        cursor: !!onClick ? 'pointer' : 'auto',
        ...sx,
      }}
      onMouseEnter={() => setIsHovered(true)} // Trigger expansion on hover
      onMouseLeave={() => setIsHovered(false)} // Trigger shrinkage on mouse out
    >
      {(!iconPlacement || iconPlacement === 'start') && icon}
      <Fade in={isHovered} timeout={2000}>
        <Typography
          variant="subtitle1"
          sx={{
            marginLeft:
              (!iconPlacement || iconPlacement === 'start') && isHovered
                ? 1.5
                : 0,
            marginRight: iconPlacement === 'end' && isHovered ? 1.5 : 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </Typography>
      </Fade>

      {iconPlacement === 'end' && icon}
    </Box>
  );
};

export default ExpandableIconLabel;
