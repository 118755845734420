import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { Box, Typography, useTheme } from '@mui/material';
import {
  FiltersConfiguration,
  useEditFiltersConfiguration,
} from 'api/hooks/useConfigurations';
import { DeleteIcon } from 'components/animatedIcons/delete';
import { FilePenLineIcon } from 'components/animatedIcons/file-pen-line';
import { IconButton } from 'components/general/IconButton/IconButton';
import { IOSSwitch } from 'components/Switch/Switch';
import { useSnackbar } from 'context/SnackbarContext';
import { useTranslation } from 'context/TranslationContext';

interface FiltersConfigurationProps {
  filtersConfiguration: FiltersConfiguration;
  onEdit: () => void;
  onDelete: () => void;
}

const ConfigurationItem = ({
  filtersConfiguration,
  onEdit,
  onDelete,
}: FiltersConfigurationProps) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const { editFiltersConfiguration } = useEditFiltersConfiguration();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{
        border: `1px solid ${theme.palette.border.primary}`,
        paddingY: 1,
        paddingX: 3,
        borderRadius: 8,
      }}
      mb={1}
    >
      <Typography variant="body1" sx={{ flex: '0 0 20%' }}>
        {filtersConfiguration.name}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AppShortcutIcon
            sx={{
              color: filtersConfiguration.in_app_active
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          />
          <IOSSwitch
            sx={{ m: 1 }}
            checked={filtersConfiguration.in_app_active}
            onChange={async () => {
              try {
                editFiltersConfiguration(
                  {
                    ...filtersConfiguration,
                    in_app_active: !filtersConfiguration.in_app_active,
                  },
                  {
                    onSuccess: () => {
                      showMessage(
                        !filtersConfiguration.in_app_active
                          ? t('filters.appAutomationEnabled')
                          : t('filters.appAutomationDisabled'),
                        'info'
                      );
                    },
                    onError: (error) => {
                      showMessage(t('filters.automationErrorApp'), 'error');
                    },
                  }
                );
              } catch (error) {
                console.error(
                  'Error during subscription or filter update:',
                  error
                );
                showMessage(t('filters.subscriptionError'), 'error');
              }
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <WhatsAppIcon
            sx={{
              color: filtersConfiguration.phone_active
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          />
          <IOSSwitch
            sx={{ m: 1 }}
            checked={filtersConfiguration.phone_active}
            onChange={() =>
              editFiltersConfiguration(
                {
                  ...filtersConfiguration,
                  phone_active: !filtersConfiguration.phone_active,
                },
                {
                  onSuccess: () => {
                    showMessage(
                      !filtersConfiguration.mail_active
                        ? t('filters.whatsappAutomationEnabled')
                        : t('filters.whatsappAutomationDisabled'),
                      'info'
                    );
                  },
                  onError: (error) => {
                    showMessage(t('filters.automationErrorWhatsapp'), 'error');
                  },
                }
              )
            }
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MailOutlineIcon
            sx={{
              color: filtersConfiguration.mail_active
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          />
          <IOSSwitch
            sx={{ m: 1 }}
            checked={filtersConfiguration.mail_active}
            onChange={() =>
              editFiltersConfiguration(
                {
                  ...filtersConfiguration,
                  mail_active: !filtersConfiguration.mail_active,
                },
                {
                  onSuccess: () => {
                    showMessage(
                      !filtersConfiguration.mail_active
                        ? t('filters.emailAutomationEnabled')
                        : t('filters.emailAutomationDisabled'),
                      'info'
                    );
                  },
                  onError: (error) => {
                    showMessage(t('filters.automationErrorEmail'), 'error');
                  },
                }
              )
            }
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <IconButton onClick={onEdit}>
          <FilePenLineIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export const ConfigurationHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{
        border: `1px solid ${theme.palette.border.primary} `,

        height: '56px',
        paddingX: 3,
        borderRadius: 8,
      }}
      mb={1}
    >
      <Typography variant="body1" sx={{ flex: '0 0 20%' }}>
        {t('filters.configurationsLabel')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flex: 1 }}>
        <Typography variant="body1">
          {t('filters.communicationsLabel')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ConfigurationItem;
