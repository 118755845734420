export const priceOptions = [
  { value: 0, label: '0€' },
  { value: 50, label: '50€' },
  { value: 100, label: '100€' },
  { value: 200, label: '200€' },
  { value: 500, label: '500€' },
  { value: 1000, label: '1,000€' },
  { value: 2000, label: '2,000€' },
  { value: 5000, label: '5,000€' },
  { value: 10000, label: '10,000€' },
  { value: 20000, label: '20,000€' },
  { value: 30000, label: '30,000€' },
  { value: 50000, label: '50,000€' },
  { value: 70000, label: '70,000€' },
  { value: 90000, label: '90,000€' },
  { value: 110000, label: '110,000€' },
  { value: 130000, label: '130,000€' },
  { value: 150000, label: '150,000€' },
  { value: 170000, label: '170,000€' },
  { value: 190000, label: '190,000€' },
  { value: 210000, label: '210,000€' },
  { value: 230000, label: '230,000€' },
  { value: 250000, label: '250,000€' },
  { value: 270000, label: '270,000€' },
  { value: 290000, label: '290,000€' },
  { value: 310000, label: '310,000€' },
  { value: 330000, label: '330,000€' },
  { value: 350000, label: '350,000€' },
  { value: 370000, label: '370,000€' },
  { value: 390000, label: '390,000€' },
  { value: 410000, label: '410,000€' },
  { value: 430000, label: '430,000€' },
  { value: 450000, label: '450,000€' },
  { value: 470000, label: '470,000€' },
  { value: 490000, label: '490,000€' },
  { value: 500000, label: '500,000€' },
  { value: 600000, label: '600,000€' },
  { value: 700000, label: '700,000€' },
  { value: 800000, label: '800,000€' },
  { value: 900000, label: '900,000€' },
  { value: 1000000, label: '1M€' },
  { value: 1100000, label: '1.1M€' },
  { value: 1200000, label: '1.2M€' },
  { value: 1300000, label: '1.3M€' },
  { value: 1400000, label: '1.4M€' },
  { value: 1500000, label: '1.5M€' },
  { value: 1600000, label: '1.6M€' },
  { value: 1700000, label: '1.7M€' },
  { value: 1800000, label: '1.8M€' },
  { value: 1900000, label: '1.9M€' },
  { value: 2000000, label: '2M€' },
  { value: 2100000, label: '2.1M€' },
  { value: 2200000, label: '2.2M€' },
  { value: 2300000, label: '2.3M€' },
  { value: 2400000, label: '2.4M€' },
  { value: 2500000, label: '2.5M€' },
  { value: 2600000, label: '2.6M€' },
  { value: 2700000, label: '2.7M€' },
  { value: 2800000, label: '2.8M€' },
  { value: 2900000, label: '2.9M€' },
  { value: 3000000, label: '3M€' },
  { value: 3100000, label: '3.1M€' },
  { value: 3200000, label: '3.2M€' },
  { value: 3300000, label: '3.3M€' },
  { value: 3400000, label: '3.4M€' },
  { value: 3500000, label: '3.5M€' },
  { value: 3600000, label: '3.6M€' },
  { value: 3700000, label: '3.7M€' },
  { value: 3800000, label: '3.8M€' },
  { value: 3900000, label: '3.9M€' },
  { value: 4000000, label: '4M€' },
  { value: 4100000, label: '4.1M€' },
  { value: 4200000, label: '4.2M€' },
  { value: 4300000, label: '4.3M€' },
  { value: 4400000, label: '4.4M€' },
  { value: 4500000, label: '4.5M€' },
  { value: 4600000, label: '4.6M€' },
  { value: 4700000, label: '4.7M€' },
  { value: 4800000, label: '4.8M€' },
  { value: 4900000, label: '4.9M€' },
  { value: 5000000, label: '5M€' },
];

export const bedroomsOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
];

export const bathroomsOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
];

export const sizeOptions = [
  { value: 30, label: '30 m²' },
  { value: 40, label: '40 m²' },
  { value: 50, label: '50 m²' },
  { value: 60, label: '60 m²' },
  { value: 70, label: '70 m²' },
  { value: 80, label: '80 m²' },
  { value: 90, label: '90 m²' },
  { value: 100, label: '100 m²' },
  { value: 110, label: '110 m²' },
  { value: 120, label: '120 m²' },
  { value: 130, label: '130 m²' },
  { value: 140, label: '140 m²' },
  { value: 150, label: '150 m²' },
  { value: 160, label: '160 m²' },
  { value: 170, label: '170 m²' },
  { value: 180, label: '180 m²' },
  { value: 190, label: '190 m²' },
  { value: 200, label: '200 m²' },
  { value: 250, label: '250 m²' },
  { value: 300, label: '300 m²' },
  { value: 350, label: '350 m²' },
  { value: 400, label: '400 m²' },
  { value: 450, label: '450 m²' },
  { value: 500, label: '500 m²' },
  { value: 550, label: '550 m²' },
  { value: 600, label: '600 m²' },
  { value: 650, label: '650 m²' },
  { value: 700, label: '700 m²' },
  { value: 750, label: '750 m²' },
  { value: 800, label: '800 m²' },
  { value: 850, label: '850 m²' },
  { value: 900, label: '900 m²' },
  { value: 950, label: '950 m²' },
  { value: 1000, label: '1,000 m²' },
  { value: 1500, label: '1,500 m²' },
  { value: 2000, label: '2,000 m²' },
  { value: 2500, label: '2,500 m²' },
  { value: 3000, label: '3,000 m²' },
  { value: 3500, label: '3,500 m²' },
  { value: 4000, label: '4,000 m²' },
  { value: 4500, label: '4,500 m²' },
  { value: 5000, label: '5,000 m²' },
];

export const amenitiesOptions = [
  { value: 'garage', label: 'Garage' },
  { value: 'basement', label: 'Basement' },
  { value: 'garden', label: 'Garden' },
  { value: 'terrace', label: 'Terrace' },
  { value: 'balcony', label: 'Balcony' },
  { value: 'parking', label: 'Parking' },
  { value: 'swimming_pool', label: 'Swimming Pool' },
];

export const listingTypeOptions = [
  { value: 'SALE', label: 'For Sale' },
  { value: 'RENT', label: 'For rent' },
  { value: 'AUCT', label: 'Auction' },
  { value: 'LSE', label: 'Leasing' },
  { value: 'EXC', label: 'Exchange' },
  /*
  { value: 'W_BUY', label: 'Want to Buy' },
  { value: 'W_RENT', label: 'Want to Rent' }*/
];

export const propertyTypeOptions = [
  { value: 'APT', label: 'Apartment' },
  { value: 'HSE', label: 'House' },
  { value: 'CON', label: 'Condo' },
  { value: 'ROM', label: 'Room' },
  { value: 'FAR', label: 'Farm' },
  { value: 'LND', label: 'Land' },
  { value: 'COM', label: 'Commercial' },
  { value: 'IND', label: 'Industrial' },
  { value: 'OFF', label: 'Office' },
  { value: 'MH', label: 'Manufactured Home' },
  { value: 'RV', label: 'Motor Home' },
  { value: 'CAR', label: 'Car Park' },
  { value: 'UNK', label: 'Unknown' },
];

export const conditionOptions = [
  { value: 'NEW', label: 'New' },
  { value: 'USED', label: 'Used' },
  { value: 'REN', label: 'Renovated' },
  { value: 'RUIN', label: 'Ruin' },
  { value: 'UNK', label: 'Unknown' },
];

export const furnitureTypeOptions = [
  { value: 'UNK', label: 'Unknown' },
  { value: 'EQP', label: 'Equipped' },
  { value: 'EMP', label: 'Empty' },
];

export const sellerTypeOptions = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'AGENCY', label: 'Agency' },
  { value: 'STATE', label: 'State' },
  { value: 'COMPANY', label: 'Company' },
  { value: 'UNK', label: 'Unknown' },
];

export const orderByOptions = [
  { value: 'price', label: 'Lower price' },
  { value: '-price', label: 'Higher price' },
  { value: 'product__sq_meters', label: 'Lower m²' },
  { value: '-product__sq_meters', label: 'Higher m²' },
  { value: 'created_at', label: 'Newer' },
  { value: '-created_at', label: 'Oldest' },
];

export const updatedAtOptions = [
  { value: 'indifferent', label: 'Indifferent' },
  { value: 'last-24-hours', label: 'Last 24 hours' },
  { value: 'last-week', label: 'Last week' },
  { value: 'last-month', label: 'Last month' },
];

export const getLabelByValue = (
  value: string | number,
  marketplaceOptions?: {
    value: string;
    label: string;
  }[]
): string | undefined => {
  const allOptions = [
    ...priceOptions,
    ...bedroomsOptions,
    ...bathroomsOptions,
    ...sizeOptions,
    ...amenitiesOptions,
    ...listingTypeOptions,
    ...propertyTypeOptions,
    ...conditionOptions,
    ...(marketplaceOptions ?? []),
  ];

  const option = allOptions.find((option) => option.value === value);
  return option ? option.label : undefined;
};
