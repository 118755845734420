import {
  AddBusiness,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from 'components/general/CustomButton/CustomButton';
import { useState } from 'react';

import { useTranslation } from 'context/TranslationContext';
import { useAddListing } from '../../api/hooks/useListing';

export const AddListing = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { addListing } = useAddListing();

  // State for property details form
  const [propertyDetails, setPropertyDetails] = useState({
    title: 'Apartamento T1',
    description: '',
    parish_name: '',
    listing_type: '',
    condition: '',
    price: '',
    currency: '',
    property_type: '',
    furniture_type: null,
    heating_type: null,
    kitchen_type: null,
    bedrooms: 0,
    bathrooms: 0,
    sq_meters: 0,
    media: [] as File[],
  });

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setPropertyDetails({
      ...propertyDetails,
      [name]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newMedia = Array.from(files);
      setPropertyDetails({
        ...propertyDetails,
        media: [...propertyDetails.media, ...newMedia],
      });
    }
  };

  const handleAddListing = () => {
    const mediaItems = propertyDetails.media.map((file) => ({
      media_file: file,
      external_url: '', // Add appropriate external URL if any
      media_type: 'I',
      media_desc: '',
    }));
    const url =
      (process.env.REACT_APP_PUBLIC_URL || 'http://localhost:3000/') +
      'listing/';
    const requestData = {
      title: propertyDetails.title, // Add appropriate title
      media: mediaItems, // Ensure media is correctly assigned
      product: {
        parish_name: propertyDetails.parish_name,
        listing_type: propertyDetails.listing_type,
        price: propertyDetails.price,
        property_type: propertyDetails.property_type,
        bedrooms: Number(propertyDetails.bedrooms),
        bathrooms: Number(propertyDetails.bathrooms),
        sq_meters: Number(propertyDetails.sq_meters),
        furniture_type: propertyDetails.furniture_type,
        heating_type: propertyDetails.heating_type,
        kitchen_type: propertyDetails.kitchen_type,
        condition: propertyDetails.condition,
        parish: null,
      }, // Add appropriate property
      url: url, // Add appropriate URL
      currency: 'EUR', // Add appropriate currency
      price_flexibility: 'FIX', // Add appropriate price flexibility
      marketplace_cfg: 1, // Add appropriate marketplace name
      id: 0,
      price: propertyDetails.price,
      description: propertyDetails.description,
    };

    addListing(requestData);
    setShowModal(false);
  };

  return (
    <>
      <IconButton onClick={() => setShowModal(true)}>
        <AddBusiness />
      </IconButton>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>{t('addListing.title')}</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label={t('addListing.titleLabel')}
            value={propertyDetails.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="description"
            label={t('addListing.descriptionLabel')}
            value={propertyDetails.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ background: 'white' }}>
              {t('addListing.listingType')}
            </InputLabel>
            <Select
              name="listing_type"
              value={propertyDetails.listing_type}
              onChange={handleChange}
            >
              <MenuItem value="SALE">{t('addListing.sale')}</MenuItem>
              <MenuItem value="RENT">{t('addListing.rent')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ background: 'white' }}>
              {t('addListing.condition')}
            </InputLabel>
            <Select
              name="condition"
              value={propertyDetails.condition}
              onChange={handleChange}
            >
              <MenuItem value="NEW">{t('addListing.new')}</MenuItem>
              <MenuItem value="USED">{t('addListing.used')}</MenuItem>
              <MenuItem value="RUIN">{t('addListing.ruin')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('addListing.propertyType')}</InputLabel>
            <Select
              name="property_type"
              value={propertyDetails.property_type}
              onChange={handleChange}
            >
              <MenuItem value="house">{t('addListing.house')}</MenuItem>
              <MenuItem value="apartment">{t('addListing.apartment')}</MenuItem>
              <MenuItem value="commercial">
                {t('addListing.commercial')}
              </MenuItem>
              <MenuItem value="land">{t('addListing.land')}</MenuItem>
            </Select>
          </FormControl>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ gap: 1 }}
          >
            <TextField
              name="price"
              label={t('addListing.price')}
              value={propertyDetails.price}
              onChange={handleChange}
              fullWidth
              margin="normal"
              type="number"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('addListing.currency')}</InputLabel>
              <Select
                name="currency"
                value={propertyDetails.currency}
                onChange={handleChange}
              >
                <MenuItem value="EUR">€</MenuItem>
                <MenuItem value="USD">$</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem>
                <MenuItem value="JPY">JPY</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <TextField
            name="bedrooms"
            label={t('addListing.bedrooms')}
            value={propertyDetails.bedrooms}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            name="bathrooms"
            label={t('addListing.bathrooms')}
            value={propertyDetails.bathrooms}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            name="sq_meters"
            label={t('addListing.area')}
            value={propertyDetails.sq_meters}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
          />
          {/* Media Upload Section */}
          <Box
            sx={{
              border: '2px dashed grey',
              padding: 2,
              borderRadius: 2,
              textAlign: 'center',
              marginTop: 2,
            }}
          >
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="file-input"
              type="file"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="file-input">
              <IconButton
                color="primary"
                component="span"
                sx={{ marginBottom: 2 }}
              >
                <CloudUploadIcon />
              </IconButton>
              <Typography variant="body2">
                {t('addListing.dragAndDrop')}
              </Typography>
            </label>
            <Box mt={2}>
              {propertyDetails.media.map((file, index) => (
                <Typography key={index}>{file.name}</Typography>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary">
            {t('addListing.cancel')}
          </Button>
          <CustomButton onClick={handleAddListing} color="primary">
            {t('addListing.add')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
