import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import fetchClient, { put } from 'api/rest/fetch-client';

export interface Notification {
  id: string;
  created_at: string;
  title: string;
  body: string;
  type: string;
  extras: {
    listing_id: string;
    filter_id: string;
  };
  read: boolean;
}

type ReadNotificationRequest = {
  id: string;
  read: boolean;
};

export function useNotifications(
  options?: Omit<UseQueryOptions<Notification[], Error>, 'queryKey' | 'queryFn'>
) {
  const queryClient = useQueryClient();
  const {
    data: notifications,
    error,
    ...rest
  } = useQuery<Notification[], Error>({
    queryKey: ['notifications'], // Unique key for the query
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}user-notifications/`
      );
      return response.data; // Assuming the API returns the user profile data directly
    },
    ...options,
  });

  const { mutate: notificationRead, error: notificationReadError } =
    useMutation({
      mutationFn: async (request: ReadNotificationRequest) => {
        const response = await put(
          `${fetchClient.defaults.baseURL}user-notifications/${request.id}/`,
          request
        );
        return response;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] });
      },
      onError: () => {},
    });

  console.log('notifications', notifications);
  return {
    notifications: notifications || [],
    error,
    ...rest,
    notificationRead,
  };
}
