import { Box, Typography } from '@mui/material';
import { GradientContainer } from '../../components/general/GradientContainer/GradientContainer';

import { useTranslation } from 'context/TranslationContext';

const NotFoundBox = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <GradientContainer
        sx={{
          textAlign: 'center',
          padding: 4,
          borderRadius: 2,
          maxWidth: '400px',
        }}
      >
        <Typography variant="h6" color="primary" gutterBottom>
          {t('notFoundBox.title')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('notFoundBox.description')}
        </Typography>
      </GradientContainer>
    </Box>
  );
};

export default NotFoundBox;
