import { useTheme } from '@mui/material';
import { ArrowLeftIcon } from 'components/animatedIcons/arrow-left';
import { IconButton } from 'components/general/IconButton/IconButton';
import { useNavigate } from 'react-router-dom';

interface BackwardsButtonProps {
  redirectTo: string;
}

export const BackwardsButton = ({ redirectTo }: BackwardsButtonProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(redirectTo);
  };

  return (
    <IconButton
      sx={{
        marginRight: 1,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        minWidth: '40px',
      }}
      onClick={handleClick}
    >
      <ArrowLeftIcon color={theme.palette.icon.secondary} />
    </IconButton>
  );
};
