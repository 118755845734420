import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Listing, useListingDetails } from '../api/hooks/useListing';

interface ListingDetailsContextType {
  listingDetails: Listing | undefined;
  isLoading: boolean;
}

const ListingDetailsContext = createContext<
  ListingDetailsContextType | undefined
>(undefined);

export const ListingDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id } = useParams<{ id: string }>();

  const { listingDetails, isLoading } = useListingDetails({ id: id || '' });

  return (
    <ListingDetailsContext.Provider value={{ listingDetails, isLoading }}>
      {children}
    </ListingDetailsContext.Provider>
  );
};

export const useListingDetailsContext = () => {
  const context = useContext(ListingDetailsContext);
  if (!context) {
    throw new Error(
      'useListingDetailsContext must be used within a ListingDetailsProvider'
    );
  }
  return context;
};
