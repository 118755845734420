import {
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Alert,
  AlertColor,
  IconButton,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';

import React, { createContext, ReactNode, useContext, useState } from 'react';

interface SnackbarContextType {
  showMessage: (message: string, severity?: AlertColor) => void;
}

interface SnackbarMessage {
  key: string;
  message: string;
  severity: AlertColor;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<SnackbarMessage[]>([]);
  const theme = useTheme();

  const showMessage = (msg: string, sev: AlertColor = 'info') => {
    const newMessage = {
      key: msg, // Unique key for each snackbar
      message: msg,
      severity: sev,
    };
    if (!messages.find((message) => message.key === msg)) {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  const handleClose = (
    key: string,
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.key !== key)
    );
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <div style={{ position: 'fixed', top: 16, right: 16, zIndex: 1400 }}>
        {messages.map((msg, index) => (
          <Snackbar
            key={msg.key}
            sx={{
              opacity: 1,
              alignItems: 'center',
              transition: 'transform 0.3s ease-out',
              transform: 'translateY(0)',
              marginTop: index > 0 ? 8 : 0, // Add margin between snackbars
            }}
            open={true}
            autoHideDuration={4000}
            onClose={(event, reason) => handleClose(msg.key, event, reason)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={(event) => handleClose(msg.key, event)}
              severity={msg.severity}
              sx={{
                backgroundColor:
                  msg.severity === 'error'
                    ? theme.palette.alert.error
                    : theme.palette.alert.info,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
              }}
              icon={
                msg.severity === 'error' ? (
                  <ErrorIcon sx={{ color: 'white' }} />
                ) : (
                  <InfoIcon sx={{ color: 'white' }} />
                )
              }
              action={
                <IconButton
                  size="small"
                  onClick={(event) => handleClose(msg.key, event)}
                >
                  <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
              }
            >
              <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                {msg.message}
              </Typography>
            </Alert>
          </Snackbar>
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
