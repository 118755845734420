import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import CustomTextField from 'components/general/CustomTextField/CustomTextField';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'context/TranslationContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { commonColors } from 'theme/theme';

type ResetPasswordFormState = {
  password: string;
  new_password: string;
  id: string | undefined;
  token: string | undefined;
};

// Shared text field styles
const textFieldStyles: SxProps = {
  '& .MuiFormLabel-root': { fontSize: '14px', color: commonColors.darkGray },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '32px',
    height: '50px',
    '& fieldset': { borderColor: commonColors.windyBlue },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: commonColors.primaryBlue,
    },
  },
};

const ResetPasswordForm = ({
  user_id,
  user_token,
}: {
  user_id: string;
  user_token: string;
}) => {
  const [formState, setFormState] = useState<ResetPasswordFormState>({
    password: '',
    new_password: '',
    id: user_id,
    token: user_token,
  });

  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [passwordError, setPasswordError] = useState('');

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle password reset submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { password, new_password } = formState;
    if (!password || !new_password) {
      setPasswordError(t('reset_password.fill_required_error')); // Use translation key
      return;
    }
    try {
      await resetPassword(formState);
      navigate('/login');
    } catch (error) {
      setPasswordError(t('reset_password.failure_message')); // Use translation key
      console.error('error:', error);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: '2rem',
        maxWidth: '500px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      gap={4}
    >
      <Typography variant="h3" color={commonColors.primaryBlue}>
        {t('reset_password.title')} {/* Use translation key */}
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          maxHeight: '60vh', // Sets maximum height for form fields
          overflowY: 'auto', // Enables vertical scrolling
          overflowX: 'hidden',
          '&::-webkit-scrollbar': { display: 'none' },
          mb: 2,
        }}
      >
        {(
          Object.keys(formState).filter((x) => x.includes('password')) as Array<
            keyof ResetPasswordFormState
          >
        ).map((field) => (
          <CustomTextField
            key={field}
            label={t(`reset_password.${field}_label`)}
            name={field}
            variant="outlined"
            value={formState[field]}
            onChange={handleInputChange}
            required={['password', 'new_password'].includes(field)}
            type={field.includes('password') ? 'password' : 'text'}
            fullWidth
            margin="normal"
            errorMessage={passwordError}
            sx={textFieldStyles}
          />
        ))}
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          borderRadius: 36,
          fontWeight: 'bold',
          height: '48px',
        }}
      >
        {t('reset_password.submit_button')}
      </Button>
    </Stack>
  );
};

export default ResetPasswordForm;
