import { alpha, createTheme } from '@mui/material/styles';
import { hexToRgba } from 'components/utils/utils';

declare module '@mui/material/styles' {
  interface Palette {
    sidebar: {
      background: string;
      icon: string;
      selectedIconBorder: string;
    };
    icon: {
      primary: string;
      secondary: string;
    };
    border: {
      primary: string;
      secondary: string;
    };

    alert: {
      info: string;
      error: string;
    };
    gradient: {
      backgroundGradient: string;
      backgroundGradientBottomTop: string;
    };
  }

  interface PaletteOptions {
    sidebar: {
      background: string;
      icon?: string;
      selectedIconBorder?: string;
    };
    icon?: {
      primary?: string;
      secondary?: string;
    };
    border?: {
      primary?: string;
      secondary?: string;
    };
    alert?: {
      info?: string;
      error?: string;
    };
    gradient?: {
      backgroundGradient?: string;
      backgroundGradientBottomTop?: string;
    };
  }
}

export const commonColors = {
  primary: '#22E1DC',
  primaryBlue: '#1E3756',
  windyBlue: '#ABBBC8',
  lightGreen: '#48D7B3',
  white: '#FBFFFE',
  darkGray: '#231F1F',
  black: '#121213',
  red: '#ff4545',
};

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: commonColors.primaryBlue,
    },
    background: {
      default: '#F8FFFD',
      paper: '#FDFFFF',
    },
    sidebar: {
      background: commonColors.primaryBlue,
      icon: commonColors.white,
      selectedIconBorder: commonColors.primary,
    },
    text: {
      primary: commonColors.primaryBlue,
      secondary: commonColors.white,
      disabled: commonColors.windyBlue,
    },
    action: {
      selected: commonColors.primaryBlue,
      focus: commonColors.windyBlue,
    },
    icon: {
      primary: commonColors.primaryBlue,
      secondary: commonColors.primaryBlue,
    },
    border: {
      primary: hexToRgba(commonColors.windyBlue, 0.4),
      secondary: hexToRgba(commonColors.primaryBlue, 0.8),
    },
    alert: {
      info: hexToRgba(commonColors.windyBlue, 0.8),
      error: hexToRgba(commonColors.red, 0.8),
    },
    gradient: {
      backgroundGradient: `linear-gradient(to bottom left, rgb(226, 235, 236) 10%, rgba(18, 211, 171, 0) 75%)`,
      backgroundGradientBottomTop: `linear-gradient(to top, rgba(226, 235, 236, 0.7) 1%, rgba(18, 211, 171, 0) 10%)`,
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '32px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '28px', // 30% less than 32px
      },
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '28px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '19.6px', // 30% less than 28px
      },
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: '24px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '20px', // 30% less than 24px
      },
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '18px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '16px', // 30% less than 18px
      },
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '16px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '14px', // 30% less than 16px
      },
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '12px', // 30% less than 14px
      },
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      color: commonColors.primaryBlue,
      '@media (max-width:600px)': {
        fontSize: '10px', // 30% less than 12px
      },
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '10px',
      color: commonColors.primaryBlue,
    },
    subtitle2: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '10px',
      color: commonColors.white,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: commonColors.primaryBlue,
          textTransform: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: commonColors.primaryBlue,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: commonColors.primaryBlue,
          borderWidth: '2px',
          borderColor: commonColors.primaryBlue,
          '&:hover': {
            backgroundColor: alpha(commonColors.primaryBlue, 0.24), // Change color on hover
          },
        },
        deleteIcon: {
          color: commonColors.primaryBlue!,
          height: '20px',
          width: '20px',
          '& > svg': { height: '20px', width: '20px' }, // Set delete icon color
          '&:hover': {
            color: commonColors.primaryBlue, // Change color on hover
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: commonColors.primary,
    },
    background: {
      default: commonColors.darkGray,
      paper: commonColors.black,
    },
    sidebar: {
      background: commonColors.black,
      icon: commonColors.white,
      selectedIconBorder: commonColors.primary,
    },
    text: {
      primary: commonColors.white,
      secondary: commonColors.white,
      disabled: commonColors.windyBlue,
    },
    action: {
      selected: commonColors.primary,
      focus: commonColors.lightGreen,
    },
    icon: {
      primary: commonColors.primary,
      secondary: commonColors.white,
    },
    border: {
      primary: hexToRgba(commonColors.primary, 0.3),
      secondary: hexToRgba(commonColors.primary, 0.3),
    },
    alert: {
      info: hexToRgba(commonColors.primary, 0.8),
      error: hexToRgba(commonColors.red, 0.8),
    },
    gradient: {
      backgroundGradient: `linear-gradient(to bottom left, rgb(36, 80, 79) 1%, rgba(18, 211, 171, 0) 75%)`,
      backgroundGradientBottomTop: `linear-gradient(to top, rgb(36, 80, 79, 0.2) 1%, rgba(18, 211, 171, 0) 75%)`,
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '32px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '28px', // 30% less than 32px
      },
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '28px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '24px', // 30% less than 28px
      },
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '24px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '20px', // 30% less than 24px
      },
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '18px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '16px', // 30% less than 18px
      },
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '16px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '14px', // 30% less than 16px
      },
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '12px', // 30% less than 14px
      },
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      color: commonColors.white,
      '@media (max-width:600px)': {
        fontSize: '10px', // 30% less than 12px
      },
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '10px',
      color: commonColors.white,
    },
    subtitle2: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '10px',
      color: commonColors.primaryBlue,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: commonColors.white,
          borderColor: commonColors.primary,
          textTransform: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: commonColors.primary,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            fontSize: '14px',
            borderRadius: '32px',

            '& fieldset': {
              borderColor: commonColors.white, // border color
            },
            '&:hover fieldset': {
              borderColor: commonColors.primary, // border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: commonColors.primary, // border color on focus
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: commonColors.white,
          borderWidth: '2px',
          borderColor: commonColors.primary,
          '&:hover': {
            backgroundColor: 'rgba(34, 225, 220, 0.24)', // Change color on hover
          },
        },
        deleteIcon: {
          color: commonColors.white,
          height: '20px',
          width: '20px',
          '& > svg': { height: '20px', width: '20px' }, // Set delete icon color
          '&:hover': {
            color: commonColors.primary, // Change color on hover
          },
        },
      },
    },
  },
});
