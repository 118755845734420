import {
  Box,
  Button,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { EditIcon } from 'components/animatedIcons/edit';
import { ShareIcon } from 'components/animatedIcons/share';
import CustomTextField from 'components/general/CustomTextField/CustomTextField';
import { OptionalGradientContainer } from 'components/general/GradientContainer/GradientContainer';
import { IconButton } from 'components/general/IconButton/IconButton';
import { useState } from 'react';
import CustomPhotoInput from '../../components/general/CustomPhotoInput/CustomPhotoInput';

import { useTranslation } from 'context/TranslationContext';

export const PersonalInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('John Doe');
  const [email, setEmail] = useState('john.doe@example.com');
  const [address, setAddress] = useState('123 Main St, Cityville');
  const [phone, setPhone] = useState('+1234567890');
  const [agency, setAgency] = useState('Doe Real Estate');
  const [photo, setPhoto] = useState<File | null>(null);

  return (
    <Stack>
      <OptionalGradientContainer
        sx={{
          height: isMobile && !editMode ? 300 : 650,
          width: '100%',
          overflow: 'hidden',
          transition: 'height 1s ease',
        }}
      >
        <Stack sx={{ margin: isMobile ? 2 : 4 }}>
          <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}>
            <IconButton onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setEditMode(!editMode)}>
              <ShareIcon />
            </IconButton>
          </Box>
          <CustomPhotoInput initialPhoto={photo} onChange={setPhoto} />
          {!isMobile || editMode ? (
            <Box mt={2} display="flex" flexDirection="column" gap={2}>
              <CustomTextField
                label={t('profile.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!editMode}
              />
              <CustomTextField
                label={t('profile.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!editMode}
              />
              <CustomTextField
                label={t('profile.address')}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={!editMode}
              />
              <CustomTextField
                label={t('profile.phone_number')}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                disabled={!editMode}
              />
              <CustomTextField
                label={t('profile.agency')}
                value={agency}
                onChange={(e) => setAgency(e.target.value)}
                disabled={!editMode}
              />
              <Box>
                <GradientButton onClick={() => setEditMode(false)}>
                  {t('profile.save')}
                </GradientButton>
              </Box>
            </Box>
          ) : null}
        </Stack>
      </OptionalGradientContainer>
    </Stack>
  );
};

const GradientButton = styled(Button)({
  background: 'linear-gradient(90deg, #172435 0%, #22e1dc 50%, #172435 100%)',
  backgroundSize: '200% 100%',
  borderRadius: '30px',
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0px 4px 10px rgba(0, 255, 255, 0.3)',
  padding: '10px 20px',
  transition: 'background-position 1s',
  backgroundPosition: '0% 0%',
  '&:hover': {
    backgroundPosition: '100% 0%',
  },
});

export default PersonalInfo;
