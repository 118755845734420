import { Box, Stack, SxProps, Typography, Tooltip } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

const IconLabel = ({
  icon,
  label,
  value,
  onClick,
  sx,
  hideLabel = false, // New prop with default value
}: {
  icon: ReactElement;
  label: string;
  value?: string | number | boolean | null;
  onClick?: () => void;
  sx?: SxProps;
  hideLabel?: boolean; // New prop type definition
}) => {
  const displayValue =
    typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;

  return (
    <Box
      onClick={onClick}
      sx={{
        padding: 0,
        cursor: !!onClick || hideLabel ? 'pointer' : 'auto',
        gap: 1,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Tooltip
          title={label}
          arrow
          disableHoverListener={!hideLabel}
          placement="left"
        >
          {icon}
        </Tooltip>
        {!hideLabel && <Typography variant="subtitle1">{label}</Typography>}
      </Box>
      <Typography
        sx={{ marginLeft: '4px' }}
        variant="subtitle1"
        fontWeight={'bold'}
      >
        {displayValue ?? '-'}
      </Typography>
    </Box>
  );
};

export default IconLabel;
