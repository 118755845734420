import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Listing, useDeleteListing } from 'api/hooks/useListing';
import ListingCard from 'components/general/ListingCard/ListingCard';
import NoResultsbox from 'pages/ListingDetails/NoResultsBox';
import LoadingIndicator from 'pages/ListingPage/LoadingIndicator';
import { useEffect, useState } from 'react';

interface ListingsProps {
  results: Listing[];
  isBackOffice: boolean;
  isLoading: boolean;
  resetResults?: () => void;
}

const ListingsContainer = ({
  results,
  isLoading,
  isBackOffice,
  resetResults,
}: ListingsProps) => {
  const [visibleCount, setVisibleCount] = useState(0);

  const theme = useTheme();
  const { deleteListing } = useDeleteListing(resetResults);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function handleDeleteListing(id: string) {
    deleteListing(id.toString());
  }

  useEffect(() => {
    if (!isLoading) {
      const interval = setInterval(() => {
        setVisibleCount((prevCount) => {
          if (prevCount < results.length) {
            return prevCount + 1;
          }
          clearInterval(interval);
          return prevCount;
        });
      }, 300);
      return () => clearInterval(interval);
    }
  }, [isLoading, results.length]);
  if (!isLoading && results.length === 0) {
    return <NoResultsbox />;
  }

  if (isMobile) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          gap: 2,
          marginTop: 3,
        }}
      >
        {results.map((listing, index) => (
          <ListingCard
            isBackOffice={isBackOffice}
            handleDeleteListing={handleDeleteListing}
            key={index}
            listing={listing}
            sx={{
              opacity: index < visibleCount ? 1 : 0,
              transition: 'opacity 1.5s ease',
            }}
          />
        ))}
        {isLoading && <LoadingIndicator />}
      </Stack>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          flex: 1,
          gap: 2,
          marginTop: 3,
          marginLeft: 2,
        }}
      >
        {results.map((listing, index) => (
          <ListingCard
            handleDeleteListing={handleDeleteListing}
            key={index}
            isBackOffice={isBackOffice}
            listing={listing}
            sx={{
              opacity: index < visibleCount ? 1 : 0,
              transition: 'opacity 1.5s ease',
            }}
          />
        ))}
        {isLoading && <LoadingIndicator />}
      </Box>
    </Box>
  );
};

export default ListingsContainer;
