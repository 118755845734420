import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import fetchClient, { post } from 'api/rest/fetch-client';
import { useSnackbar } from 'context/SnackbarContext';

export type FiltersConfiguration = {
  id: string;
  name: string;
  in_app_active: boolean;
  mail_active: boolean;
  phone_active: boolean;
  parameters: {
    filters: {
      property_name: string;
      operator: string;
      value: string | number;
    }[];
  };
};

type GetFiltersConfigurationsResponse = {
  results: FiltersConfiguration[];
};

export const useAddFiltersConfiguration = (handleClose: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: addFiltersConfiguration, ...rest } = useMutation({
    mutationFn: (request: FiltersConfiguration) => {
      return post(`${fetchClient.defaults.baseURL}/userfilters/`, request);
    },
    onSuccess: (data) => {
      console.log('Filters Configuration added successfully:', data);
      queryClient.invalidateQueries({ queryKey: ['filterOptions'] });
      handleClose();
    },
  });

  return { addFiltersConfiguration, ...rest };
};

export const useGetFiltersConfigurations = (
  options?: Omit<UseQueryOptions<any, Error>, 'queryKey' | 'queryFn'>
) => {
  const { data: filtersConfigurationsResponse, ...rest } = useQuery<
    GetFiltersConfigurationsResponse,
    Error
  >({
    queryKey: ['filterOptions'],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}/userfilters/`
      );
      return response.data;
    },
    ...options,
  });

  return {
    filtersConfigurations: filtersConfigurationsResponse?.results || [],
    ...rest,
  };
};

export const useDeleteFiltersConfiguration = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteFiltersConfiguration, ...rest } = useMutation({
    mutationFn: (id: string) => {
      return fetchClient.delete(
        `${fetchClient.defaults.baseURL}/userfilters/${id}/`
      );
    },
    onSuccess: () => {
      console.log('Filters Configuration deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['filterOptions'] });
    },
  });

  return { deleteFiltersConfiguration, ...rest };
};

export const useEditFiltersConfiguration = (handleClose?: () => void) => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();

  const { mutate: editFiltersConfiguration, ...rest } = useMutation({
    mutationFn: (updatedConfig: FiltersConfiguration & { id: string }) => {
      return fetchClient.put(
        `${fetchClient.defaults.baseURL}/userfilters/${updatedConfig.id}/`,
        updatedConfig
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['filterOptions'] });
      handleClose?.();
    },
  });

  return { editFiltersConfiguration, ...rest };
};
