import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

// Enable PWA by registering the service worker
serviceWorkerRegistration.register();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js') // Registering the Firebase messaging service worker
    .then(function (registration) {
      console.log(
        'Firebase Service Worker registered with scope:',
        registration.scope
      );
    })
    .catch(function (err) {
      console.error('Firebase Service Worker registration failed:', err);
    });
}

// For measuring performance
reportWebVitals();
