import PlaceIcon from '@mui/icons-material/Place';
import { Box, SxProps, useTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Listing } from 'api/hooks/useListing';
import ListingCard from 'components/general/ListingCard/ListingCard';
import { formatPrice } from 'components/utils/utils';
import { SnackbarProvider } from 'context/SnackbarContext';
import { ThemeProvider, useThemeContext } from 'context/ThemeContext';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { MemoryRouter, useNavigate } from 'react-router-dom';
import './styles.css';

const queryClient = new QueryClient();
interface Coordinates {
  lat: number;
  lng: number;
}

export const MapContainer = ({
  id,
  center,
  listings,
  enableCard = false,
  sx,
}: {
  id: string;
  center: Coordinates;
  listings: Listing[];
  enableCard?: boolean;
  sx?: SxProps;
}) => {
  const { isLightTheme } = useThemeContext();
  const theme = useTheme();

  const navigate = useNavigate();
  const handleCardClick = (listing: Listing) => {
    navigate(`/listing/${listing.id}`, { state: { listing } });
  };

  useEffect(() => {
    if (listings.length > 0) {
      const map = new maplibregl.Map({
        container: id,
        style: isLightTheme
          ? 'https://tiles.openfreemap.org/styles/liberty'
          : 'https://tiles.openfreemap.org/styles/fiord',
        center: [center.lng, center.lat],
        zoom: 14.2,
        pitch: 60,
      });

      listings.forEach((listing) => {
        const latitude = listing.product.latitude;
        const longitude = listing.product.longitude;

        if (latitude && longitude) {
          const markerElement = document.createElement('div');
          markerElement.style.display = 'flex';
          markerElement.style.alignItems = 'center';
          markerElement.style.justifyContent = 'center';

          const iconContainer = document.createElement('div');
          const root = ReactDOM.createRoot(iconContainer);
          root.render(
            <PlaceIcon
              sx={{
                color: theme.palette.primary.main,
                width: '20px',
                height: '20px',
              }}
            />
          );

          markerElement.appendChild(iconContainer);

          new maplibregl.Marker({ element: markerElement })
            .setLngLat([longitude, latitude])
            .addTo(map);

          const hoverPopup = new maplibregl.Popup({
            offset: 25,
            closeButton: false,
          })
            .addClassName('some-class')
            .setHTML(
              `<body1 class="body1"> ${formatPrice(listing.price)}</body1>`
            );

          markerElement.addEventListener('mouseover', () => {
            hoverPopup.setLngLat([longitude, latitude]).addTo(map);
          });

          markerElement.addEventListener('mouseleave', () => {
            hoverPopup.remove();
          });

          if (enableCard) {
            const clickedCardPopup = new maplibregl.Popup({
              closeOnClick: false,
            }).setHTML(
              ReactDOMServer.renderToString(
                <MemoryRouter>
                  <ThemeProvider>
                    <QueryClientProvider client={queryClient}>
                      <SnackbarProvider>
                        <a
                          href={`${window.location.origin}${window.location.pathname}/${listing.id}`}
                          style={{ textDecoration: 'none', color: 'inherit' }} // Added style
                        >
                          <ListingCard
                            listing={listing}
                            isBackOffice={false}
                            handleExternalCardClick={() =>
                              handleCardClick(listing)
                            }
                          />
                        </a>
                      </SnackbarProvider>
                    </QueryClientProvider>
                  </ThemeProvider>
                </MemoryRouter>
              )
            );

            markerElement.addEventListener('click', () => {
              hoverPopup?.remove();
              clickedCardPopup.setLngLat([longitude, latitude]).addTo(map);
            });

            clickedCardPopup._content.addEventListener('mouseleave', () => {
              clickedCardPopup?.remove();
            });
          }
        }
      });

      return () => {
        map.remove();
      };
    }
  }, [id, center, listings, enableCard]);

  return (
    <Box
      sx={{
        height: '85%',
        borderRadius: '6px',
        '& .maplibregl-ctrl-bottom-right': {
          display: 'none !important',
        },
        ...sx,
      }}
      id={id}
    />
  );
};
