import { useEffect, useRef } from 'react';

class Bubble {
  constructor(canvasWidth, canvasHeight, color) {
    this.maxHeight = canvasHeight;
    this.maxWidth = canvasWidth;
    this.color = color;
    this.randomise();
  }

  generateDecimalBetween(min, max) {
    return (Math.random() * (max - min) + min).toFixed(2);
  }

  update() {
    this.posX = this.posX - this.movementX;
    this.posY = this.posY - this.movementY;

    if (this.posY < 0 || this.posX < 0 || this.posX > this.maxWidth) {
      this.randomise();
      this.posY = this.maxHeight;
    }
  }

  randomise() {
    this.size = this.generateDecimalBetween(3, 6);
    this.movementX = this.generateDecimalBetween(-0.5, 0.5);
    this.movementY = this.generateDecimalBetween(0.7, 0.1);
    this.posX = this.generateDecimalBetween(0, this.maxWidth);
    this.posY = this.generateDecimalBetween(0, this.maxHeight);
  }
}

const Background = ({ id, color, sx, children }) => {
  const canvasRef = useRef(null);
  const bubblesList = useRef([]);

  const colors = [
    'rgba(253, 193, 77, 0.39)', // #fdc14d with 0.39 alpha
    'rgba(72, 215, 179, 0.39)', // #48d7b3 with 0.39 alpha
    'rgba(116, 255, 234, 0.39)', // #74ffea with 0.39 alpha
  ];

  const generateBubbles = (canvasWidth, canvasHeight) => {
    const bubbleCount = Math.sqrt(canvasWidth * canvasHeight * 0.001);
    const bubbles = [];
    for (let i = 0; i < bubbleCount; i++) {
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      bubbles.push(new Bubble(canvasWidth, canvasHeight, randomColor));
    }
    return bubbles;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    bubblesList.current = generateBubbles(canvas.width, canvas.height);

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      bubblesList.current.forEach((bubble) => {
        bubble.update();

        const radius = bubble.size; // The radius of the hexagon (same as the circle's radius)
        const centerX = bubble.posX; // X coordinate for the center of the hexagon
        const centerY = bubble.posY; // Y coordinate for the center of the hexagon

        // Select a random color

        ctx.beginPath();

        // Draw the six sides of the hexagon
        for (let i = 0; i < 6; i++) {
          const angle = (i * Math.PI) / 3; // 60 degrees in radians
          const x = centerX + radius * Math.cos(angle); // X coordinate of the vertex
          const y = centerY + radius * Math.sin(angle); // Y coordinate of the vertex

          if (i === 0) {
            ctx.moveTo(x, y); // Move to the first vertex
          } else {
            ctx.lineTo(x, y); // Draw a line to the next vertex
          }
        }

        ctx.closePath(); // Close the path to form the hexagon
        ctx.fillStyle = bubble.color; // Use the randomized color for fill
        ctx.fill();
        ctx.strokeStyle = bubble.color; // Use the same color for stroke
        ctx.stroke();
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      bubblesList.current = generateBubbles(canvas.width, canvas.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [color]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <canvas
        ref={canvasRef}
        id={id}
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 0, ...sx }}
      />
      <div style={{ height: '100%', display: 'grid', zIndex: 1 }}>
        {children}
      </div>
    </div>
  );
};

export default Background;
