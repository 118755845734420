import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MediaItem } from '../../../../api/hooks/useListing';

interface ImageSwiperProps {
  images: MediaItem[];
  openOneSwipper?: boolean;
}

const ImageSwiper: React.FC<ImageSwiperProps> = ({
  images,
  openOneSwipper,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const modules = isMobile ? [Pagination] : [FreeMode, Navigation, Thumbs];
  return (
    <>
      <Swiper
        style={
          {
            '--swiper-navigation-color': theme.palette.icon.primary,
            '--swiper-pagination-color': theme.palette.icon.primary,
            width: '100%',
            height: isMobile ? '300px' : '70%',
            borderRadius: 8,
          } as React.CSSProperties
        }
        spaceBetween={10}
        navigation={isMobile ? false : true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={modules}
        className="mainSwiper"
      >
        {images.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              style={{
                width: '100%',
                borderRadius: 8,
                height: '400px',
                objectFit: 'cover',
                border: '1px solid #ddd',
                overflow: 'hidden',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              }}
              src={slide.external_url}
              alt={slide.media_desc || ''}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {!isMobile && !openOneSwipper && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="thumbSwiper"
          style={{ marginTop: '10px' }}
        >
          {images.map((slide, index) => (
            <SwiperSlide key={index}>
              <img
                style={{
                  width: '100%',
                  height: '100px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                }}
                src={slide.external_url}
                alt={slide.media_desc || ''}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default ImageSwiper;
