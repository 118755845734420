import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  SpeedDialIcon,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import ConfigurationItem, { ConfigurationHeader } from './ConfigurationItem';

import {
  useAddFiltersConfiguration,
  useDeleteFiltersConfiguration,
  useEditFiltersConfiguration,
  useGetFiltersConfigurations,
} from 'api/hooks/useConfigurations';
import Filters from 'components/Filters/Filters';
import { LabelButton } from 'components/general/LabelButton/LabelButton';
import Popover from 'components/general/Popover/Popover';
import { useFilterContext } from 'context/FilterContext';
import { useSnackbar } from 'context/SnackbarContext';
import { useTranslation } from 'context/TranslationContext';
import CustomTextField from '../../../components/general/CustomTextField/CustomTextField';

const ConfigurationSection = () => {
  const { t } = useTranslation(); // initialize translation hook
  const [openModal, setOpenModal] = useState(false);
  const { generateRequestFiltersObj, populateFiltersFromObj, resetFilters } =
    useFilterContext();

  const handleClose = () => {
    resetFilters();
    setOpenModal(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { showMessage } = useSnackbar();
  const { addFiltersConfiguration } = useAddFiltersConfiguration(handleClose);
  const { editFiltersConfiguration } = useEditFiltersConfiguration(handleClose);
  const { deleteFiltersConfiguration } = useDeleteFiltersConfiguration();
  const { filtersConfigurations } = useGetFiltersConfigurations();
  const [editMode, setEditMode] = useState(false);
  const [configId, setConfigId] = useState('');
  const [configName, setConfigName] = useState('');
  const configBeingEdited = filtersConfigurations.find(
    (config) => config.id === configId
  );

  const handleAddConfig = () => {
    addFiltersConfiguration(
      {
        id: '',
        name: configName ?? '',
        in_app_active: false,
        mail_active: false,
        phone_active: false,
        parameters: { filters: generateRequestFiltersObj() },
      },
      {
        onSuccess: () => {
          showMessage(t('filters.configurationAdded'), 'info'); // Use translation key
        },
        onError: (error) => {
          showMessage(t('filters.configurationAddError'), 'error'); // Use translation key
        },
      }
    );
  };

  const handleEditConfig = () => {
    editFiltersConfiguration(
      {
        id: configId,
        name: configName ?? '',
        in_app_active: configBeingEdited?.in_app_active ?? false,
        mail_active: configBeingEdited?.mail_active ?? false,
        phone_active: configBeingEdited?.phone_active ?? false,
        parameters: { filters: generateRequestFiltersObj() },
      },
      {
        onSuccess: () => {
          showMessage(t('filters.configurationEdited'), 'info'); // Use translation key
        },
        onError: (error) => {
          showMessage(t('filters.configurationEditError'), 'error'); // Use translation key
        },
      }
    );
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <Stack sx={{ padding: 2 }} gap={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          gap: 2,
          marginLeft: 'auto',
        }}
        onClick={() => {
          setEditMode(false);
          setConfigName('');
          handleOpenModal();
        }}
      >
        <IconButton
          sx={{
            height: '42px',
            width: '42px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
          }}
        >
          <SpeedDialIcon
            sx={{ '.MuiSpeedDialIcon-icon': { color: 'white' } }}
          />
        </IconButton>

        {!isMobile && (
          <LabelButton
            label={t('filters.addNewConfiguration')} // Use translation key
            sx={{ height: '32px' }}
          />
        )}
      </Box>
      <Box mt={2}>
        {filtersConfigurations.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            {t('filters.noConfigurationsFound')} {/* Use translation key */}
          </Typography>
        ) : (
          filtersConfigurations.map((filtersConfiguration, index) => (
            <>
              {index === 0 && <ConfigurationHeader />}
              <ConfigurationItem
                key={index}
                filtersConfiguration={filtersConfiguration}
                onEdit={() => {
                  populateFiltersFromObj(
                    filtersConfiguration.parameters.filters
                  );

                  setConfigId(filtersConfiguration?.id ?? '');
                  setConfigName(filtersConfiguration.name);
                  setEditMode(true);
                  handleOpenModal();
                }}
                onDelete={() => {
                  filtersConfiguration.id &&
                    deleteFiltersConfiguration(filtersConfiguration?.id, {
                      onSuccess: () => {
                        showMessage(t('filters.configurationDeleted'), 'info'); // Use translation key
                      },
                      onError: (error) => {
                        showMessage(
                          t('filters.configurationDeleteError'),
                          'error'
                        ); // Use translation key
                      },
                    });
                }}
              />
            </>
          ))
        )}
      </Box>
      <Popover
        open={openModal}
        onClose={handleClose}
        sx={
          isMobile
            ? {
                height: '80%',
                insetArea: 'center',
                width: '90%',
                overflowY: 'hidden',
              }
            : {}
        }
      >
        <Stack m={4}>
          <DialogContent
            sx={{
              paddingTop: '10px !important',

              gap: isMobile ? 2 : 0,
            }}
          >
            <CustomTextField
              required
              label={t('filters.name')} // Use translation key
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
            />
            <Filters modalView={true} />
          </DialogContent>
          <DialogActions sx={{ gap: 3 }}>
            <Button
              onClick={handleClose}
              sx={{ borderRadius: '32px' }}
              variant="outlined"
            >
              {t('filters.cancel')} {/* Use translation key */}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ borderRadius: '32px' }}
              onClick={() => {
                editMode ? handleEditConfig() : handleAddConfig();
              }}
            >
              {t('filters.save')} {/* Use translation key */}
            </Button>
          </DialogActions>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ConfigurationSection;
