import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CustomButtonProps extends ButtonProps {
  label?: string;
}

const CustomButton = ({ label, children, ...props }: CustomButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '8px',
        textTransform: 'none',
        boxShadow: `0 3px 5px ${theme.palette.background.default}`,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.background.default,
        },
        padding: theme.spacing(1.5, 3),
      }}
      {...props}
    >
      {label || children}
    </Button>
  );
};

export default CustomButton;
