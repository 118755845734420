import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import fetchClient from 'api/rest/fetch-client';
export interface UserProfileApiResponse {
  id: number;
  country: string | null;
  region: string | null;
  city: string | null;
  user_type: string;
  user: number;
  is_staff: boolean;
  marketplace_subscriptions: any[];
  region_subscriptions: any[];
  last_login: string | null;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  date_joined: string;
  groups: any[];
  user_notifications: {
    title: string;
    body: string;
    created_at: string;
    read: false;
  }[];
  user_permissions: any[];
}
[];

export function useGetUserProfile(
  options?: Omit<
    UseQueryOptions<UserProfileApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) {
  const {
    data: userProfileApiResponse,
    error,
    ...rest
  } = useQuery<UserProfileApiResponse, Error>({
    queryKey: ['userProfile'], // Unique key for the query
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}user/`
      );
      return response.data; // Assuming the API returns the user profile data directly
    },
    ...options,
  });

  return {
    userProfile: userProfileApiResponse || null,
    error,
    ...rest,
  };
}
