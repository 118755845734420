import BathtubIcon from '@mui/icons-material/Bathtub';
import BedIcon from '@mui/icons-material/Bed';
import GarageIcon from '@mui/icons-material/Garage';
import GradeIcon from '@mui/icons-material/Grade';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import StraightenIcon from '@mui/icons-material/Straighten';
import {
  Box,
  Button,
  DialogActions,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ExpandIcon } from 'components/animatedIcons/expand';
import SwipeableViews from 'react-swipeable-views';
import { OptionalGradientContainer } from '../../../../components/general/GradientContainer/GradientContainer';
import IconLabel from '../../../../components/general/IconLabel/IconLabel';
import { ReactComponent as PersonIcon } from '../../../../components/icons/person.svg';
import { useListingDetailsContext } from '../../../../context/ListingDetailsContext';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import ChurchIcon from '@mui/icons-material/Church';
import DeckIcon from '@mui/icons-material/Deck';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import PoolIcon from '@mui/icons-material/Pool';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import { useCreateReport, useGetReports } from 'api/hooks/useReport';
import CustomSelect from 'components/general/CustomSelect/CustomSelect';
import { IconButton } from 'components/general/IconButton/IconButton';
import { LabelButton } from 'components/general/LabelButton/LabelButton';
import Popover from 'components/general/Popover/Popover';
import { useTranslation } from 'context/TranslationContext';
import { useState } from 'react';
import { AnimatedBox } from '../../../../components/general/GradientContainer/AnimatedBox';
import { formatPrice } from '../../../../components/utils/utils';

export const Details = () => {
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  if (isMobileOrTablet) {
    return (
      <Stack>
        <ListingInfo />
        <PropertyDetails />
        <ListingDescription />
      </Stack>
    );
  }
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Stack sx={{ flex: 1 }}>
        <PropertyDetails />
        <ListingDescription />
      </Stack>
      <Box sx={{ width: '25%' }}>
        <ListingInfo />
      </Box>
    </Box>
  );
};

export const PropertyDetails = () => {
  const { listingDetails } = useListingDetailsContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [activeIndex, setActiveIndex] = useState(0);

  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }

  const { product } = listingDetails;
  const gradientContainers = [
    [
      {
        icon: <StraightenIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.sq_meters
          ? `${Math.trunc(product.sq_meters)} m²`
          : t('property_details.no_size'),
        label: t('property_details.meters'),
      },
      {
        icon: <GradeIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.condition,
        label: t('property_details.condition'),
      },
      {
        icon: <ChurchIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.parish,
        label: t('property_details.parish'),
      },
      {
        icon: <GradeIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.energy_rating,
        label: t('property_details.energy_rating'),
      },
    ],
    [
      {
        icon: <BedIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.bedrooms,
        label: t('property_details.bedrooms'),
      },
      {
        icon: <BathtubIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.bathrooms,
        label: t('property_details.bathrooms'),
      },
    ],
    [
      {
        icon: <SoupKitchenIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.kitchen_type,
        label: t('property_details.kitchen'),
      },
      {
        icon: <HeatPumpIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.heating_type,
        label: t('property_details.heating'),
      },
      {
        icon: <AcUnitIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.air_conditioning
          ? t('property_details.yes')
          : t('property_details.no'),
        label: t('property_details.ac'),
      },
      {
        icon: <AddRoadIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.flooring_material,
        label: t('property_details.flooring'),
      },
    ],
    [
      {
        icon: <GarageIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.garage
          ? t('property_details.yes')
          : t('property_details.no'),
        label: t('property_details.garage'),
      },
      {
        icon: <LocalFloristIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.garden
          ? t('property_details.yes')
          : t('property_details.no'),
        label: t('property_details.garden'),
      },
      {
        icon: <DeckIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.terrace
          ? t('property_details.yes')
          : t('property_details.no'),
        label: t('property_details.terrace'),
      },
      {
        icon: <PoolIcon sx={{ color: theme.palette.icon.secondary }} />,
        value: product.swimming_pool
          ? t('property_details.yes')
          : t('property_details.no'),
        label: t('property_details.pool'),
      },
    ],
  ];

  const handleIndexChange = (index: number) => {
    setActiveIndex(index);
  };

  const detailsCards = gradientContainers.map((icons, index) => (
    <OptionalGradientContainer
      key={index}
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '0px',
        borderTopLeftRadius: index === 0 ? '16px' : 'none',
        borderBottomLeftRadius: index === 0 ? '16px' : 'none',
        borderTopRightRadius: index === 3 ? '16px' : 'none',
        borderBottomRightRadius: index === 3 ? '16px' : 'none',
        borderRight: index !== 3 ? 'none' : 'auto',
        marginY: 1,
      }}
    >
      <Stack sx={{ marginY: 2, marginX: 3, gap: 1 }}>
        {icons.map((item, idx) => (
          <IconLabel
            key={idx}
            icon={item.icon}
            value={item.value}
            label={item.label}
          />
        ))}
      </Stack>
    </OptionalGradientContainer>
  ));

  return (
    <Box>
      <Typography variant="h6">Details</Typography>
      {isMobileOrTablet ? (
        <Box>
          <SwipeableViews index={activeIndex} onChangeIndex={handleIndexChange}>
            {detailsCards}
          </SwipeableViews>

          <Stack direction="row" justifyContent="center" sx={{ marginTop: 2 }}>
            {gradientContainers.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor:
                    index === activeIndex ? 'primary.main' : 'grey.400',
                  margin: '0 4px',
                  transition: 'background-color 0.3s',
                }}
              />
            ))}
          </Stack>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', width: '100%' }}>{detailsCards}</Box>
      )}
    </Box>
  );
};

export const ListingDescription = () => {
  const { listingDetails } = useListingDetailsContext();
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }

  return (
    <Stack gap={2} sx={{ marginY: 3 }}>
      <Typography variant="h6">{t('property_details.description')}</Typography>
      <OptionalGradientContainer
        sx={{
          height: showAll ? 'fit-content' : '200px',
          boxSizing: 'border-box',
          padding: 2,
          gap: 2,
        }}
      >
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            height: 'auto',
            overflow: 'hidden',
            textAlign: 'justify',
          }}
        >
          {listingDetails.description}
        </Typography>
        <LabelButton
          sx={{ width: 'fit-content' }}
          onClick={() => setShowAll(!showAll)}
          label={showAll ? 'Show Less' : 'Read More'}
        />
      </OptionalGradientContainer>
    </Stack>
  );
};

export const ListingInfo = () => {
  const { listingDetails } = useListingDetailsContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const { reports } = useGetReports(
    { id: listingDetails?.id },
    {
      enabled: !!listingDetails,
    }
  );

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [showAll, setShowAll] = useState(isMobileOrTablet ? false : true);
  const [reportReason, setReportReason] = useState('');
  const [reportMessage, setReportMessage] = useState('');
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { createReport } = useCreateReport();

  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }

  const header = (
    <Box
      onClick={isMobileOrTablet ? () => setShowAll(!showAll) : undefined}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        placeItems: 'center',
        height: '40px',
      }}
    >
      <Box>
        <Typography variant="h6">
          {formatPrice(listingDetails.price)}
        </Typography>
        {!isMobileOrTablet && (
          <Typography variant="subtitle1">
            {t('property_details.negotiable')}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignContent: 'center' }} gap={2}>
        {isMobileOrTablet && (
          <IconButton hasBorder={false} onClick={() => setShowAll(!showAll)}>
            <ExpandIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  const content = (
    <Stack sx={{ gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <PersonIcon />
        <Typography variant="body2">
          {listingDetails.publisher ?? 'No data'}
        </Typography>
      </Box>

      <LabelButton
        label={t('property_details.report')}
        onClick={() => setIsReportModalOpen(true)}
      />
      <Popover
        open={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
      >
        <Stack sx={{ margin: 2, gap: 2, minWidth: '400px' }}>
          <CustomSelect
            label={t('property_details.reason')}
            value={reportReason}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setReportReason(e.target.value as string);
            }}
            options={[
              { value: 'SCAM', label: t('property_details.scam') },
              { value: 'SPAM', label: t('property_details.spam') },
              { value: 'FAKE', label: t('property_details.fake') },
              { value: 'REMOVED', label: t('property_details.removed') },
              { value: 'INCOMPLETE', label: t('property_details.incomplete') },
            ]}
          />
          <TextField
            label={t('property_details.report_message')}
            value={reportMessage}
            onChange={(e) => setReportMessage(e.target.value)}
            multiline
            rows={4}
          />
        </Stack>{' '}
        <DialogActions sx={{ gap: 3 }}>
          <Button
            onClick={() => setIsReportModalOpen(false)}
            sx={{ borderRadius: '32px' }}
            variant="outlined"
          >
            {t('property_details.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ borderRadius: '32px' }}
            onClick={() => {
              createReport(
                {
                  listing: listingDetails.id,
                  reason: reportReason,
                  report: reportMessage,
                },
                {
                  onSuccess: () => {
                    setIsReportModalOpen(false);
                  },
                }
              );
            }}
          >
            {t('property_details.report')}
          </Button>
        </DialogActions>
      </Popover>
    </Stack>
  );

  return (
    <AnimatedBox
      isGradient={false}
      hasBoxShadow={true}
      sx={{
        height: isMobileOrTablet ? (showAll ? '300px' : '40px') : 'fit-content',
        width: 'auto',
        minWidth: 'auto',
        marginTop: isMobileOrTablet ? 1 : 4,
        marginBottom: isMobileOrTablet ? 2 : 0,
        transition: 'height 1s ease-in-out',
        overflow: 'hidden',
        gap: 2,
      }}
    >
      <Stack
        sx={{
          margin: isMobileOrTablet ? '0px 12px 4px 12px' : 2,
          gap: 2,
        }}
      >
        {header}
        {content}
      </Stack>
    </AnimatedBox>
  );
};
