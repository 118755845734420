import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import CustomTextField from 'components/general/CustomTextField/CustomTextField';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'context/TranslationContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { commonColors } from 'theme/theme';

type RecoverPasswordFormState = {
  email: string;
};

// Shared text field styles
const textFieldStyles: SxProps = {
  '& .MuiFormLabel-root': { fontSize: '14px', color: commonColors.darkGray },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    color: commonColors.primaryBlue,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '32px',
    height: '50px',
    '& fieldset': { borderColor: commonColors.windyBlue },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: commonColors.primaryBlue,
    },
  },
};

const RecoverPasswordForm = () => {
  const [formState, setFormState] = useState<RecoverPasswordFormState>({
    email: '',
  });

  const navigate = useNavigate();
  const { recoverPassword } = useAuth();
  const { t } = useTranslation();
  const [passwordError, setPasswordError] = useState('');

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { email } = formState;
    if (!email) {
      setPasswordError(t('recover_password.fill_required_error'));
      return;
    }
    try {
      await recoverPassword(formState);
      navigate('/login');
    } catch (error) {
      setPasswordError(t('recover_password.failure_message'));
      console.error('Error:', error);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: '2rem',
        maxWidth: '500px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      gap={4}
    >
      <Typography variant="h3" color={commonColors.primaryBlue}>
        {t('recover_password.title')}
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          maxHeight: '60vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': { display: 'none' },
          mb: 2,
        }}
      >
        <CustomTextField
          label={t('recover_password.email_label')}
          name="email"
          variant="outlined"
          value={formState.email}
          onChange={handleInputChange}
          required
          fullWidth
          margin="normal"
          errorMessage={passwordError ? t('recover_password.email_error') : ''}
          sx={textFieldStyles}
        />
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          borderRadius: 36,
          fontWeight: 'bold',
          height: '48px',
        }}
      >
        {t('recover_password.send_email_button')}
      </Button>
    </Stack>
  );
};

export default RecoverPasswordForm;
