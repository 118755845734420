import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSnackbar } from 'context/SnackbarContext';
import fetchClient from '../rest/fetch-client';

type StatsApiResponse = {
  total_page_views: number;
  items_added_today: number;
  items_removed_today: number;
  users_registered_today: number;
  page_views_by_day: Record<string, number>;
  items_added_by_city: Record<string, Record<string, number>>;
  items_added_by_type: Record<string, number>;
  items_added_by_type_today: Record<string, number>;
  items_removed_by_type: Record<string, number>;
  items_removed_by_typology: Record<string, number>;
  items_removed_by_city: Record<string, Record<string, number>>;
  items_sold_by_type: Record<string, number>;
  last_inserted: number[];
  last_sold: number[];
};

type Stats = {
  totalPageViews: number;
  itemsAddedToday: number;
  itemsRemovedToday: number;
  usersRegisteredToday: number;
  pageViewsByDay: Record<string, number>;
  itemsAddedByCity: Record<string, Record<string, number>>;
  itemsAddedByType: Record<string, number>;
  itemsAddedByTypeToday: Record<string, number>;
  itemsRemovedByType: Record<string, number>;
  itemsRemovedByTypology: Record<string, number>;
  itemsRemovedByCity: Record<string, Record<string, number>>;
  itemsSoldByType: Record<string, number>;
  lastInserted: number[];
  lastSold: number[];
};

const statsData = {
  total_page_views: 8,
  items_added_today: 539,
  items_removed_today: 3,
  users_registered_today: 0,
  page_views_by_day: {
    '123': 3,
    '2024-11-09': 2,
  },
  items_added_by_city: {
    '35': {
      SALE: 1,
    },
    UNK: {
      SALE: 155,
      None: 80,
      RENT: 5,
      AUCT: 373,
      W_BUY: 5,
    },
  },
  items_added_by_type: {
    APT: 421,
    CAR: 2,
    COM: 122,
    FAR: 1,
    HSE: 177,
    IND: 34,
    LND: 335,
    None: 80,
    OFC: 33,
    UNK: 29,
  },
  items_added_by_type_today: {
    AUCT: 1565,
    None: 80,
    RENT: 5,
    SALE: 520,
    W_BUY: 5,
  },
  items_removed_by_type: {
    SALE: 6,
  },
  items_removed_by_typology: {
    '2': 2,
    '3': 1,
    None: 2,
  },
  items_removed_by_city: {
    '15': {
      SALE: 1,
    },
    UNK: {
      SALE: 4,
    },
    Vizela: {
      SALE: 1,
    },
  },
  items_sold_by_type: {
    APT: 3,
    ROM: 1,
    UNK: 1,
  },
  last_inserted: [6036, 6035, 6034, 6033, 6032, 6031, 6030, 6029, 6028, 6027],
  last_sold: [6033, 6033, 6034, 3860, 3861, 3861, 3861, 3861, 3861, 3860],
};

export function useStats(
  options?: Omit<
    UseQueryOptions<StatsApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) {
  const { showMessage } = useSnackbar();
  const {
    data: statsApiResponse,
    error,
    ...rest
  } = useQuery<StatsApiResponse, Error>({
    queryKey: ['stats'],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}/stats/`
      );

      console.log('response', response);
      return response.data;
    },
    ...options,
  });
  console.log('error', error);

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve overview information',
      'error'
    );
  }
  //change for statsApiResponse
  const statsResponse = statsData
    ? {
        totalPageViews: statsData.total_page_views,
        itemsAddedToday: statsData.items_added_today,
        itemsRemovedToday: statsData.items_removed_today,
        usersRegisteredToday: statsData.users_registered_today,
        pageViewsByDay: statsData.page_views_by_day,
        itemsAddedByCity: statsData.items_added_by_city,
        itemsAddedByType: statsData.items_added_by_type,
        itemsAddedByTypeToday: statsData.items_added_by_type_today,
        itemsRemovedByType: statsData.items_removed_by_type,
        itemsRemovedByTypology: statsData.items_removed_by_typology,
        itemsRemovedByCity: statsData.items_removed_by_city,
        itemsSoldByType: statsData.items_sold_by_type,
        lastInserted: statsData.last_inserted,
        lastSold: statsData.last_sold,
      }
    : null;
  return {
    stats: statsResponse as Stats,
    ...rest,
  };
}
