import { Box, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'context/TranslationContext';
import { GradientContainer } from '../../../../components/general/GradientContainer/GradientContainer';

const NoMediabox = ({
  sx,
  sxGradient,
}: {
  sx?: SxProps;
  sxGradient?: SxProps;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'background.default',
        ...sx,
      }}
    >
      <GradientContainer
        sx={{
          textAlign: 'center',
          padding: 4,
          borderRadius: 2,
          maxWidth: '400px',
          width: '100%',
          ...sxGradient,
        }}
      >
        <Typography variant="h6" color="primary" gutterBottom>
          {t('noMediaFound.title')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('noMediaFound.description')}
        </Typography>
      </GradientContainer>
    </Box>
  );
};

export default NoMediabox;
