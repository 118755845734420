import { Menu, MenuItem, useTheme } from '@mui/material';
import { WaypointsIcon } from 'components/animatedIcons/waypoints';
import ExpandableIconLabel from 'components/general/ExpandableIconLabel/ExpandableIconLabel';
import React, { useState } from 'react';

const ShareDropdown = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform: string) => {
    const currentUrl = window.location.href; // Get the current URL
    switch (platform) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
          '_blank'
        );
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`,
          '_blank'
        );
        break;
      case 'instagram':
        // Instagram doesn't have a direct share URL, so we can open Instagram's homepage or a custom URL
        alert(
          'Instagram sharing is not directly supported via URL. Use the app to share.'
        );
        break;
      case 'copy':
        navigator.clipboard.writeText(currentUrl);
        alert('Link copied to clipboard!');
        break;
      default:
        break;
    }
    handleClose(); // Close the dropdown after the action
  };

  return (
    <div>
      <ExpandableIconLabel
        iconPlacement={'end'}
        icon={<WaypointsIcon />}
        label={'Share'}
        color={theme.palette.icon.secondary}
        onClick={handleClick}
        endingWidth={'90px'}
        startingWidth={'24px'}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleShare('copy')}>Copy Link</MenuItem>
        <MenuItem onClick={() => handleShare('facebook')}>
          Share on Facebook
        </MenuItem>
        <MenuItem onClick={() => handleShare('twitter')}>
          Share on Twitter
        </MenuItem>
        <MenuItem onClick={() => handleShare('instagram')}>
          Share on Instagram
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ShareDropdown;
