import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, SxProps, useTheme } from '@mui/material';
import { useThemeContext } from 'context/ThemeContext';
import { ReactNode } from 'react';

export const Popover = ({
  open,
  children,
  onClose,
  sx,
}: {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  sx?: SxProps;
}) => {
  const { isLightTheme } = useThemeContext();
  const theme = useTheme();
  const boxShadow = !isLightTheme
    ? `6px 0px 6px 0px #24504f `
    : `6px 0px 6px 0px ${theme.palette.border.primary} `;
  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      PaperProps={{
        style: {
          overflowY: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          boxShadow: '6px 0px 6px 0px #24504f',
          backgroundColor: theme.palette.background.default,

          maxWidth: '90%',
          '--Paper-overlay': 'none',
        } as React.CSSProperties & { [key: string]: any },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(2px)',
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          padding: 4,
          boxShadow: boxShadow,
          backgroundColor: theme.palette.background.paper,
          margin: 1,
          maxWidth: '90%',
          '&--Paper-overlay': 'none',
        },

        ...sx,
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: '#00b3ff',
          '&:hover': {
            color: '#00ffcc',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
};

export default Popover;
