import { alpha, Box, useTheme } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: ChartJS, args: unknown, options: { color: string }) => {
    const { ctx, width, height } = chart;
    if (ctx && width && height) {
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || '#99ffff';
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }
  },
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
    customCanvasBackgroundColor: {
      color: 'transparent',
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  hover: {
    mode: 'dataset' as const,
  },
};

export const LineChart = ({
  keys,
  datasets,
  width,
  height,
}: {
  keys: string[];
  datasets: { values: number[]; color?: string }[];
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();

  const data = {
    labels: keys,
    datasets: datasets.map((dataset) => ({
      borderColor: alpha(dataset.color || theme.palette.primary.main, 0.2),
      hoverBorderColor: dataset.color || theme.palette.primary.main,
      borderWidth: 2,
      pointRadius: 5,
      pointBackgroundColor: alpha(
        dataset.color || theme.palette.primary.main,
        0.2
      ),
      pointHoverBackgroundColor: dataset.color || theme.palette.primary.main,
      data: dataset.values,
      backgroundColor: alpha(dataset.color || theme.palette.primary.main, 0.2),
    })),
  };

  return (
    <Box sx={{ width: width, height: height, cursor: 'pointer' }}>
      <Line options={options} plugins={[plugin]} data={data} />
    </Box>
  );
};
