import { Badge, Box, Button, SxProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNotifications } from 'api/hooks/useNotification';
import { BellIcon } from 'components/animatedIcons/bell';
import { ChartColumnIncreasingIcon } from 'components/animatedIcons/chart-column-increasing';
import { ChromeIcon } from 'components/animatedIcons/chrome';
import { CircleDollarSignIcon } from 'components/animatedIcons/circle-dollar-sign';
import { CompassIcon } from 'components/animatedIcons/compass';
import { UserIcon } from 'components/animatedIcons/user';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const Navbar = ({
  sx,
  onClick,
}: {
  sx?: SxProps;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const { userProfile } = useAuth();

  const { notifications } = useNotifications();
  const notificationsUnread =
    notifications?.filter((notification) => !notification.read).length ?? 0;
  const isStaff = userProfile?.is_staff;
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State to manage selected route
  const [selected, setSelected] = useState(location.pathname);

  const buttonStyles = (isSelected: boolean) => ({
    width: isMobile ? '30px' : '100%',
    justifyContent: 'center',
    paddingLeft: '0px',
    backgroundColor: 'transparent',
    '& > svg': {
      color: isSelected ? theme.palette.sidebar.selectedIconBorder : 'white',
    },
    borderRadius: 0,
    left: 1,
    borderRight:
      isSelected && !isMobile
        ? `1px solid  ${theme.palette.sidebar.selectedIconBorder}`
        : 'none',
    paddingRight: isSelected && !isMobile ? '0px' : '1px',
    borderTop:
      isSelected && isMobile
        ? `1px solid  ${theme.palette.sidebar.selectedIconBorder}`
        : 'none',
    '&:hover': {
      opacity: 1, // Change opacity on hover
      transition: 'background-color 0.3s, opacity 0.3s',
    },
    marginBottom: isMobile ? 'auto' : '16px',
  });

  return (
    <Box
      sx={{
        zIndex: 1,
        height: isMobile ? '84px' : 'auto',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: isMobile ? '20px' : '0px',
        borderBottomRightRadius: isMobile ? '0px' : '20px',
        boxShadow: isMobile
          ? `0px -1px 8px -3px ${theme.palette.primary.main}`
          : `1px 0px 8px -3px ${theme.palette.primary.main}`,

        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        paddingTop: isMobile ? 0 : 4,
        paddingBottom: 0,
        gap: 3,
        backgroundColor: theme.palette.sidebar.background,
        borderRight: isMobile
          ? 'none '
          : `1px solid  ${theme.palette.border.secondary}`,
        borderTop: !isMobile
          ? 'none '
          : `1px solid  ${theme.palette.border.secondary}`,
        ...sx,
      }}
    >
      <Button
        component={Link}
        sx={buttonStyles(selected === '/notifications')}
        to="/notifications" // Check if selected
        onClick={() => setSelected('/notifications')} // Update selected on click
      >
        <Badge badgeContent={notificationsUnread} color="error">
          <BellIcon
            color={
              selected === '/notifications'
                ? theme.palette.sidebar.selectedIconBorder
                : 'white'
            }
          />
        </Badge>
      </Button>
      <Button
        component={Link}
        sx={buttonStyles(selected === '/overview')}
        to="/overview" // Check if selected
        onClick={() => setSelected('/overview')} // Update selected on click
      >
        <ChartColumnIncreasingIcon
          color={
            selected === '/overview'
              ? theme.palette.sidebar.selectedIconBorder
              : 'white'
          }
        />
      </Button>

      <Button
        component={Link}
        to="/listing"
        sx={buttonStyles(selected === '/listing')} // Check if selected
        onClick={() => setSelected('/listing')} // Update selected on click
      >
        <CompassIcon
          color={
            selected === '/listing'
              ? theme.palette.sidebar.selectedIconBorder
              : 'white'
          }
        />
      </Button>

      {isStaff && (
        <Button
          component={Link}
          to="/backoffice"
          sx={buttonStyles(selected === '/backoffice')} // Check if selected
          onClick={() => setSelected('/backoffice')} // Update selected on click
        >
          <ChromeIcon
            color={
              selected === '/backoffice'
                ? theme.palette.sidebar.selectedIconBorder
                : 'white'
            }
          />
        </Button>
      )}

      <Button
        component={Link}
        to="/profile"
        sx={buttonStyles(selected === '/profile')} // Check if selected
        onClick={() => setSelected('/profile')} // Update selected on click
      >
        <UserIcon
          color={
            selected === '/profile'
              ? theme.palette.sidebar.selectedIconBorder
              : 'white'
          }
        />
      </Button>

      <Button
        component={Link}
        to="/subscription"
        sx={buttonStyles(selected === '/subscription')} // Check if selected
        onClick={() => setSelected('/subscription')} // Update selected on click
      >
        <CircleDollarSignIcon
          color={
            selected === '/subscription'
              ? theme.palette.sidebar.selectedIconBorder
              : 'white'
          }
        />
      </Button>
    </Box>
  );
};

export default Navbar;
