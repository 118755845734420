import {
  Box,
  Button,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as DotIcon } from 'components/icons/dot.svg';
import { ReactComponent as EyeIcon } from 'components/icons/eye_tracking.svg';
import { ReactComponent as HomeIcon } from 'components/icons/home_pin.svg';

import { MediaItem } from 'api/hooks/useListing';

import { CircularProgressWithLabel } from 'components/general/CustomCircularProgress/CustomCircularProgress';
import { AnimatedBox } from 'components/general/GradientContainer/AnimatedBox';
import { MapContainer } from 'components/general/Map/Map';
import { useListingDetailsContext } from 'context/ListingDetailsContext';
import { ReactNode, useState } from 'react';
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import NoMediabox from './NoMediaBox';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules

import { CursorClickIcon } from 'components/animatedIcons/cursor-click';
import { ExpandIcon } from 'components/animatedIcons/expand';
import { getLabelByValue } from 'components/Filters/FiltersOptionsValues';
import ExpandableIconLabel from 'components/general/ExpandableIconLabel/ExpandableIconLabel';
import { IconButton } from 'components/general/IconButton/IconButton';
import Popover from 'components/general/Popover/Popover';
import ShareDropdown from 'components/general/ShareDropdown/ShareDropdown';
import {
  calculateDefinedPropertiesPercentage,
  getListingTypeColor,
} from 'components/utils/utils';
import { useTranslation } from 'context/TranslationContext';
import { BackwardsButton } from 'pages/ListingDetails/page-components/BackwardsButton';
import ImageSwiper from './ImageSwiper';

export const PropertyHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { listingDetails } = useListingDetailsContext();
  if (!listingDetails) {
    return <Box></Box>;
  }

  const backwardsElement = <BackwardsButton redirectTo={'/listing'} />;
  const titleElement = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Typography variant="h4">
        {listingDetails.title.substring(0, 30) + '...'}
      </Typography>
      <ExpandableIconLabel
        iconPlacement={'end'}
        icon={<DotIcon />}
        label={getLabelByValue(listingDetails.listing_type ?? '') ?? ''}
        color={getListingTypeColor(listingDetails.listing_type)}
      />
    </Box>
  );
  const kpisElement = (
    <Box
      sx={{
        display: 'flex',
        gap: 2,

        paddingLeft: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CircularProgressWithLabel
          value={calculateDefinedPropertiesPercentage(listingDetails)}
        />
        <Typography variant="subtitle1">
          {t('propertyMedia.info_available')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CircularProgressWithLabel value={75} />
        <Typography variant="subtitle1">
          {t('propertyMedia.ai_evaluation')}
        </Typography>
      </Box>
    </Box>
  );

  const actionsElement = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <ExpandableIconLabel
        iconPlacement={'end'}
        icon={<CursorClickIcon />}
        label={listingDetails.marketplace_name ?? 'Link'}
        color={theme.palette.icon.secondary}
        onClick={() => {
          window.open(listingDetails.url, '_blank');
        }}
        endingWidth={'90px'}
        startingWidth={'24px'}
      />
      <ShareDropdown />
    </Box>
  );

  if (isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          gap: 2,
          justifyContent: 'space-between',
          marginX: 2,
        }}
      >
        {backwardsElement}
        {kpisElement}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {backwardsElement}
          {titleElement}
        </Box>
        {actionsElement}
      </Box>
      <Box sx={{ width: '25%', gap: 2 }}>{kpisElement}</Box>
    </Box>
  );
};
export const PropertyMedia = () => {
  const { listingDetails } = useListingDetailsContext();
  if (!listingDetails) {
    return <Box></Box>;
  }
  return (
    <MediaWrapper sx={{ marginY: 2, gap: 2 }}>
      <Media images={listingDetails.media} sx={{ flex: 1, width: '75%' }} />
      <Location />
    </MediaWrapper>
  );
};

const MediaWrapper = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <Stack sx={sx}>{children}</Stack>
  ) : (
    <Box sx={{ display: 'flex', ...sx }}>{children}</Box>
  );
};
export const Media = ({
  openOneSwipper = false,
  images,
  sx,
}: {
  openOneSwipper?: boolean;
  images: MediaItem[];
  sx?: SxProps;
}) => {
  const [showImagesModal, setShowImagesModal] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isMobile || openOneSwipper) {
    return <ImageSwiper openOneSwipper={openOneSwipper} images={images} />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          height: 'fit-content',
          maxHeight: '400px',
          minHeight: '400px',
          ...sx,
        }}
      >
        {!images || images.length === 0 ? (
          <NoMediabox />
        ) : (
          <>
            <Box
              sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                width: '50%',
              }}
            >
              {images[0]?.external_url && (
                <img
                  onClick={() => setShowImagesModal(true)}
                  src={images[0].external_url}
                  alt={images[0].media_desc}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                    height: '100%',
                    display: 'flex',
                    placeSelf: 'center',
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
            <ImageGrid
              images={images.slice(1, 5)}
              setShowImagesModal={setShowImagesModal}
            />
          </>
        )}
      </Box>

      {showImagesModal && (
        <Popover
          open={showImagesModal}
          onClose={() => setShowImagesModal(false)}
        >
          <Box
            sx={{
              padding: 2,
              display: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '500px',
              height: 'fit-content',
              borderRadius: '8px',
            }}
          >
            <ImageSwiper images={images} />
          </Box>
        </Popover>
      )}
    </>
  );
};

const Location = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { listingDetails } = useListingDetailsContext();
  const [showMapModal, setShowMapModal] = useState(false);
  const [showMapContainer, setShowMapContainer] = useState(
    isMobile ? false : true
  );
  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }
  const mobileHeight = showMapContainer ? '332px' : '40px';
  return (
    <>
      <AnimatedBox
        isGradient={false}
        hasBoxShadow={true}
        sx={{
          width: isMobile ? 'auto' : '25%',
          minWidth: isMobile ? 'auto' : '25%',
          height: isMobile ? mobileHeight : 'auto',
          justifyContent: 'space-between',
          transition: 'height 1s ease-in-out',
        }}
      >
        <MapContainer
          id="1"
          center={{
            lat: listingDetails.product.latitude ?? 0,
            lng: listingDetails.product.longitude ?? 0,
          }}
          listings={[listingDetails]}
          sx={{ margin: isMobile ? '0px' : '12px' }}
        />

        <MapFooter
          onClick={() =>
            isMobile
              ? setShowMapContainer(!showMapContainer)
              : setShowMapModal(true)
          }
        />
      </AnimatedBox>

      {showMapModal && (
        <MapPopover
          open={showMapModal}
          onClose={() => setShowMapModal(false)}
        />
      )}
    </>
  );
};

const MapPopover = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { listingDetails } = useListingDetailsContext();
  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }
  return (
    <Popover open={open} onClose={onClose}>
      <Box
        sx={{
          width: '600px',
          height: '500px',
          bgcolor: 'background.paper',
          borderRadius: '8px',
          margin: isMobile ? '0px' : '12px',
        }}
      >
        <MapContainer
          id="2"
          center={{
            lat: listingDetails.product.latitude ?? 0,
            lng: listingDetails.product.longitude ?? 0,
          }}
          listings={[listingDetails]}
          sx={{ height: '100%' }}
        />
      </Box>
    </Popover>
  );
};

const MapFooter = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { listingDetails } = useListingDetailsContext();
  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }
  return (
    <Box
      onClick={isMobile ? onClick : undefined}
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: '0px 12px 0px 12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: 0.5,
        }}
      >
        <HomeIcon color={theme.palette.icon.primary} />
        <Typography variant="subtitle1">
          {listingDetails.product.parish_name ?? t('propertyMedia.no_location')}
        </Typography>
      </Box>
      <IconButton hasBorder={false} onClick={onClick}>
        <ExpandIcon />
      </IconButton>
    </Box>
  );
};

const ImageGrid = ({
  images,
  setShowImagesModal,
}: {
  images: MediaItem[];
  setShowImagesModal: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        width: '50%',
        height: 'auto',
      }}
    >
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            flex: '1 1 calc(50% - 10px)', // Two columns with spacing
            position: 'relative', // For image positioning
            border: '1px solid #ddd', // Border around each box
            borderRadius: '8px', // Rounded corners
            overflow: 'hidden', // Hide overflow to prevent image from exceeding box
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            height: 'calc(50% - 6px)',
          }}
        >
          <img
            onClick={() => setShowImagesModal(true)}
            src={image.external_url}
            alt={image.media_desc}
            style={{
              width: '100%',
              cursor: 'pointer',
              height: '100%', // Optional: to fill the box
              objectFit: 'cover', // Cover the entire box area
            }}
          />
          {(index === 3 || index === images.length - 1) && (
            <Button
              variant="contained"
              sx={{
                borderRadius: 35,
                position: 'absolute',
                bottom: '16px',
                right: '16px',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={() => setShowImagesModal(true)}
            >
              <EyeIcon />
              <Typography variant="subtitle2">
                {' '}
                {t('propertyMedia.all_photos')}
              </Typography>
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
};
