import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'context/TranslationContext';

const LoadingIndicator = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <CircularProgress color="primary" />
      <Typography variant="body1" color="text.secondary">
        {t('loading.label')}
      </Typography>
    </Box>
  );
};

export default LoadingIndicator;
