import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAsCNaYk-HojbcT5F4fKmIRnBp3vKyhon4',
  authDomain: 'realhive-e812e.firebaseapp.com',
  projectId: 'realhive-e812e',
  storageBucket: 'realhive-e812e.appspot.com',
  messagingSenderId: '648528140637',
  appId: '1:648528140637:web:14d305ca9947bf686bb0b4',
  measurementId: 'G-E2YZFNYRPH',
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
