import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import React from 'react';

interface Option {
  value: string | number;
  label: React.ReactNode;
}

interface CustomSelectProps extends Omit<SelectProps, 'variant'> {
  label: string;
  options: Option[];
  sx?: SxProps;
}

const CustomSelect = ({ label, options, sx, ...props }: CustomSelectProps) => {
  const theme = useTheme();

  return (
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 120,
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: '8px',
        ...sx,
      }}
    >
      <InputLabel sx={{ fontSize: '0.85rem' }}>{label}</InputLabel>
      <Select
        {...props}
        label={label}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 200,
              mt: 1,
              backgroundColor: theme.palette.background.default,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              borderRadius: '8px',
            },
          },
        }}
        sx={{
          fontSize: '0.65rem', // Smaller font size for the selected text
          border: 'none',
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.icon.primary,
            border: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.selected,
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: theme.palette.icon.primary,
            border: 'none',
          },
          '& .MuiSelect-icon': {
            color: theme.palette.icon.primary,
            marginRight: '8px',
          },
          '& .MuiMenuItem-root': {
            fontSize: '0.65rem', // Smaller font size for menu items
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              fontSize: '0.85rem', // Smaller font size for individual options
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.primary.main,
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
