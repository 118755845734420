import 'swiper/swiper-bundle.css';

import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { invisibleScroll } from 'components/utils/utils';
import { useListingDetailsContext } from 'context/ListingDetailsContext';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Details } from 'pages/ListingDetails/page-components/Details/Details';
import {
  PropertyHeader,
  PropertyMedia,
} from 'pages/ListingDetails/page-components/PropertyMedia/PropertyMedia';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../ListingPage/LoadingIndicator';
import NotFoundBox from './NotFoundBox';

// Install modules
const ListingDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { listingDetails, isLoading } = useListingDetailsContext();

  const { id } = useParams<{ id: string }>();

  if (!listingDetails && isLoading) {
    return <LoadingIndicator />;
  }

  if (listingDetails?.id.toString() !== id) {
    return <LoadingIndicator />;
  }

  if (!listingDetails && !isLoading) {
    return <NotFoundBox />;
  }

  return (
    <Stack
      sx={{
        padding: isMobile ? 2 : 4,
        gap: isMobile ? 1 : 3,
      }}
    >
      <PropertyHeader />
      <Stack
        sx={
          isMobile
            ? { paddingX: '10px', ...invisibleScroll }
            : {
                paddingX: '10px',
                overflowY: 'auto',
                height: `calc(100vh - 100px)`,
              }
        }
      >
        <PropertyMedia />
        <Details />
      </Stack>
    </Stack>
  );
};

export default ListingDetails;
