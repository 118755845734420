import { useMutation } from '@tanstack/react-query';
import fetchClient, { post } from '../rest/fetch-client';

type CreatePaymentIntentRequest = {
  payment_method_id: string;
  email: string;
  subscription_type: string;
};

type PaymentCheckoutRequest = {
  subscription_type: string;
};

export const useSubscription = () => {
  const { mutate: createPaymentIntent, ...rest } = useMutation({
    mutationFn: async (request: CreatePaymentIntentRequest) => {
      const response = await post(
        `${fetchClient.defaults.baseURL}payment-intent/`,
        request
      );
      return response;
    },
    onSuccess: (data) => {},
  });

  return { createPaymentIntent, ...rest };
};

export const usePaymentCheckout = () => {
  const { mutate: paymentCheckout, ...rest } = useMutation({
    mutationFn: async (request: PaymentCheckoutRequest) => {
      const response = await post(
        `${fetchClient.defaults.baseURL}payment-checkout/`,
        request
      );
      debugger;
      return response.url; // Assuming the URL is inside response.data
    },
    onSuccess: (data) => {
      debugger;
      // Assuming data is the URL
      if (data && typeof data === 'string') {
        window.open(data, '_blank'); // Open the URL in a new tab
      } else {
        console.error('Invalid URL received:', data);
      }
    },
    onError: (error) => {
      debugger;
      // Handle errors (e.g., network issues or server errors)
      console.error('Payment checkout failed', error);
    },
  });

  return { paymentCheckout, ...rest };
};
