import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSnackbar } from 'context/SnackbarContext';
import fetchClient from '../rest/fetch-client';

interface FilterOption {
  id: string;
  name: string;
  url: string;
  active: boolean;
}
interface FilterOptionsApiResponse {
  results: FilterOption[];
}

export const useGetFilterOptions = (
  filterId: string,
  options?: Omit<
    UseQueryOptions<FilterOptionsApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) => {
  const { showMessage } = useSnackbar();
  const {
    data: filterOptionsResponse,
    error,
    ...rest
  } = useQuery<FilterOptionsApiResponse, Error>({
    queryKey: ['filterOptions', filterId],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}/${filterId}/`
      );

      return response.data;
    },
    ...options,
  });

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve filter dynamic options',
      'error'
    );
  }

  return {
    options: filterOptionsResponse?.results || [],
    ...rest,
  };
};

export type AdministrativeDivision = {
  id: number;
  name: string;
  parent_id: number | null;
};

export type Parish = AdministrativeDivision;

export type City = AdministrativeDivision & {
  parishes: Parish[];
};

export type District = AdministrativeDivision & {
  cities: City[];
};

export type Region = AdministrativeDivision & {
  districts: District[];
};

export type Country = AdministrativeDivision & {
  regions: Region[];
};

type LocationsOptionsApiResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Country[];
};

export const useGetLocationsOptions = (
  options?: Omit<
    UseQueryOptions<LocationsOptionsApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) => {
  const { showMessage } = useSnackbar();
  const {
    data: locationsOptionsResponse,
    error,
    ...rest
  } = useQuery<LocationsOptionsApiResponse, Error>({
    queryKey: ['locationsOptions'],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}/countries/`
      );

      return response.data;
    },
    ...options,
  });

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve the locations',
      'error'
    );
  }

  return {
    locations: locationsOptionsResponse?.results || [],
    ...rest,
  };
};
