import {
  Box,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomAutoComplete } from 'components/general/CustomAutoComplete/CustomAutoComplete';
import { CustomCheckbox } from 'components/general/CustomCheckbox/CustomCheckbox';
import {
  useGetFilterOptions,
  useGetLocationsOptions,
} from '../../api/hooks/useFilters';
import { useFilterContext } from '../../context/FilterContext';
import CustomSelect from '../general/CustomSelect/CustomSelect';
import {
  amenitiesOptions,
  bathroomsOptions,
  bedroomsOptions,
  conditionOptions,
  listingTypeOptions,
  orderByOptions,
  priceOptions,
  propertyTypeOptions,
  sizeOptions,
  updatedAtOptions,
} from './FiltersOptionsValues';

const Filters = ({
  modalView = false,
  resetResults,
}: {
  modalView?: boolean;
  resetResults?: () => void;
}) => {
  const {
    query,
    setQuery,
    updatedAt,
    setUpdatedAt,
    orderBy,
    setOrderBy,
    listing_type,
    setListingType,
    condition,
    setCondition,
    propertyType,
    setPropertyType,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minSize,
    setMinSize,
    maxSize,
    setMaxSize,
    bedroomFilter,
    setBedroomFilter,
    bathroomFilter,
    setBathroomFilter,
    marketplaces,
    setMarketplaces,
    amenities,
    setAmenities,
  } = useFilterContext();

  const { options } = useGetFilterOptions('marketplaces');
  console.log('options', options);
  const { locations } = useGetLocationsOptions();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleConditionChange = (newSelected: string[]) => {
    resetResults && resetResults();
    setCondition(newSelected);
  };

  const handlePropertyTypeChange = (newSelected: string[]) => {
    resetResults && resetResults();
    setPropertyType(newSelected);
  };

  const handleListingTypeChange = (newSelected: string[]) => {
    resetResults && resetResults();
    setListingType(newSelected);
  };

  const handleMarketplaceChange = (newSelected: string[]) => {
    resetResults && resetResults();
    setMarketplaces(newSelected);
  };

  const handleAmenitiesChange = (newSelected: string[]) => {
    resetResults && resetResults();
    setAmenities(newSelected);
  };

  const updatedBySelect = (
    <CustomSelect
      label="Updated in"
      value={updatedAt ?? 'indifferent'}
      onChange={(e: SelectChangeEvent<unknown>) => {
        resetResults && resetResults();
        setUpdatedAt(e.target.value as string);
      }}
      options={updatedAtOptions}
    />
  );

  const orderBySelect = (
    <CustomSelect
      label="Order By"
      value={orderBy ?? 'created_at'}
      onChange={(e: SelectChangeEvent<unknown>) => {
        resetResults && resetResults();
        setOrderBy(e.target.value as string);
      }}
      options={orderByOptions}
    />
  );

  const conditionFilter = (
    <CustomCheckbox
      title="Condition"
      options={conditionOptions}
      selectedValues={condition}
      onChange={handleConditionChange}
    />
  );

  const propertyTypeFilter = (
    <CustomCheckbox
      title="Type of Property"
      options={propertyTypeOptions}
      selectedValues={propertyType}
      onChange={handlePropertyTypeChange}
    />
  );

  const listingTypeFilter = (
    <CustomCheckbox
      title="Type of Listing"
      options={listingTypeOptions}
      selectedValues={listing_type}
      onChange={handleListingTypeChange}
    />
  );

  const priceFilters = (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <CustomSelect
        label="Min Price"
        value={minPrice ?? ''}
        onChange={(e: SelectChangeEvent<unknown>) => {
          resetResults && resetResults();
          setMinPrice(parseFloat(e.target.value as string) || 0);
        }}
        sx={{ maxWidth: '100px' }}
        options={priceOptions}
      />
      <CustomSelect
        label="Max Price"
        value={maxPrice ?? ''}
        onChange={(e: SelectChangeEvent<unknown>) => {
          resetResults && resetResults();
          setMaxPrice(parseFloat(e.target.value as string) || 0);
        }}
        sx={{ maxWidth: '100px' }}
        options={priceOptions}
      />
    </Box>
  );

  const bedroomFilterSelect = (
    <CustomSelect
      label="Bedroom"
      value={bedroomFilter ?? ''}
      onChange={(e: SelectChangeEvent<unknown>) => {
        resetResults && resetResults();
        setBedroomFilter(parseFloat(e.target.value as string) || 0);
      }}
      sx={{ maxWidth: '100px' }}
      options={bedroomsOptions}
    />
  );

  const bathroomFilterSelect = (
    <CustomSelect
      label="Bathroom"
      value={bathroomFilter ?? ''}
      onChange={(e: SelectChangeEvent<unknown>) => {
        resetResults && resetResults();
        setBathroomFilter(parseFloat(e.target.value as string) || 0);
      }}
      options={bathroomsOptions}
    />
  );

  const bathAndBedRoomsSelect = (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {bedroomFilterSelect}
      {bathroomFilterSelect}
    </Box>
  );

  const sizeFilters = (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <CustomSelect
        label="Min Size"
        value={minSize ?? ''}
        onChange={(e: SelectChangeEvent<unknown>) => {
          resetResults && resetResults();
          setMinSize(parseFloat(e.target.value as string) || 0);
        }}
        options={sizeOptions}
      />
      <CustomSelect
        label="Max Size"
        value={maxSize ?? ''}
        onChange={(e: SelectChangeEvent<unknown>) => {
          resetResults && resetResults();
          setMaxSize(parseFloat(e.target.value as string) || 0);
        }}
        options={sizeOptions}
      />
    </Box>
  );

  const locationFilter = (
    <CustomAutoComplete
      districts={locations[0]?.regions[0].districts}
      resetFilters={() => {
        resetResults && resetResults();
      }}
    />
  );

  const marketplaceFilter = (
    <CustomCheckbox
      title="Marketplaces"
      options={options.map((option) => ({
        value: option.name,
        label: option.name,
      }))}
      selectedValues={marketplaces}
      onChange={handleMarketplaceChange}
    />
  );

  const amenitiesFilter = (
    <CustomCheckbox
      title="Amenities"
      options={amenitiesOptions}
      selectedValues={amenities}
      onChange={handleAmenitiesChange}
    />
  );

  return modalView && !isMobile ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 5,
          paddingLeft: 3,
          paddingTop: 3,
        }}
      >
        {conditionFilter}
        {listingTypeFilter}
        {propertyTypeFilter}

        <Stack
          sx={{
            gap: 2,
          }}
        >
          {priceFilters}
          {bedroomFilterSelect}
          {bathroomFilterSelect}
          {sizeFilters}
        </Stack>
        <Box>{amenitiesFilter}</Box>
      </Box>
    </Box>
  ) : (
    <Stack gap={2}>
      {updatedBySelect}
      {orderBySelect}
      {locationFilter}
      {listingTypeFilter}
      {propertyTypeFilter}
      {priceFilters}
      {bathAndBedRoomsSelect}
      {sizeFilters}
      {conditionFilter}
      {marketplaceFilter}
      {amenitiesFilter}
    </Stack>
  );
};

export default Filters;
