import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { usePaymentCheckout } from 'api/hooks/useSubscription';
import { AnimatedBox } from 'components/general/GradientContainer/AnimatedBox';
import { LabelButton } from 'components/general/LabelButton/LabelButton';
import { ReactComponent as NoFeatureIcon } from 'components/icons/cancel.svg';
import { ReactComponent as FeatureIcon } from 'components/icons/check_circle.svg';
import { ReactComponent as TierIcon } from 'components/icons/tier.svg';
import { ReactComponent as TierOutlinedIcon } from 'components/icons/tier_outlined.svg';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

const tiers = [
  {
    title: 'Basic',
    features: [
      { text: 'Feature 1', available: true },
      { text: 'Feature 2', available: true },
      { text: 'Feature 3', available: true },
      { text: 'Feature 4', available: true },
      { text: 'Feature 5', available: false },
    ],
    buttonLabel: 'Subscribe',
    subscriptionType: 'basic',
    icon: TierOutlinedIcon,
    remainingDays: 15,
  },
  {
    title: 'Premium',
    features: [
      { text: 'Feature 1', available: true },
      { text: 'Feature 2', available: true },
      { text: 'Feature 3', available: true },
      { text: 'Feature 4', available: true },
      { text: 'Feature 5', available: true },
    ],
    buttonLabel: 'Subscribe',
    subscriptionType: 'premium',
    icon: TierIcon,
    remainingDays: null,
  },
  {
    title: 'Enterprise',
    features: [
      { text: 'Feature 1', available: true },
      { text: 'Feature 2', available: true },
      { text: 'Feature 3', available: true },
      { text: 'Feature 4', available: true },
      { text: 'Feature 5', available: true },
    ],
    buttonLabel: 'Contact Us',
    subscriptionType: 'enterprise',
    icon: TierOutlinedIcon,
    remainingDays: null,
  },
];

export const Subscription = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { paymentCheckout } = usePaymentCheckout();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndexChange = (index: number) => {
    setActiveIndex(index);
  };

  const gradientBoxes = tiers.map((tier, tierIndex) => (
    <AnimatedBox
      key={tierIndex}
      hasBoxShadow={true}
      hasBorder={true}
      sx={{ height: '428', width: '100%' }}
    >
      <Stack sx={{ p: 2, gap: 2, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <tier.icon />
          <Typography variant="h2">{tier.title}</Typography>
        </Box>
        <Typography variant="h6" sx={{ alignSelf: 'center' }}>
          {tier.remainingDays !== null ? `${tier.remainingDays} days left` : ''}
        </Typography>
        <Stack sx={{ gap: 1, flex: 1 }}>
          <Typography variant="body2" sx={{ alignSelf: 'left' }}>
            Features
          </Typography>
          {tier.features.map((feature, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {feature.available ? (
                <FeatureIcon color={theme.palette.icon.primary} />
              ) : (
                <NoFeatureIcon color={theme.palette.icon.primary} />
              )}
              <Typography variant="subtitle1">{feature.text}</Typography>
            </Box>
          ))}
        </Stack>
        {tier.buttonLabel && (
          <LabelButton
            label={tier.buttonLabel}
            variant="contained"
            sx={{ width: 'fit-content', alignSelf: 'center' }}
            onClick={() => {
              paymentCheckout({ subscription_type: tier.subscriptionType });
            }}
          />
        )}
      </Stack>
    </AnimatedBox>
  ));

  return (
    <Box sx={{ height: '100vh' }}>
      {isMobile ? (
        <Stack sx={{ height: '100vh', justifyContent: 'center', marginX: 4 }}>
          <SwipeableViews index={activeIndex} onChangeIndex={handleIndexChange}>
            {gradientBoxes.map((child, index) => (
              <Box key={index} sx={{ p: 2 }}>
                {child}
              </Box>
            ))}
          </SwipeableViews>
          <Stack direction="row" justifyContent="center" sx={{ marginTop: 2 }}>
            {gradientBoxes.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor:
                    index === activeIndex ? 'primary.main' : 'grey.400',
                  margin: '0 4px',
                  transition: 'background-color 0.3s',
                }}
              />
            ))}
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            m: 15,
            display: 'flex',
            gap: 15,
          }}
        >
          {gradientBoxes}
        </Box>
      )}
    </Box>
  );
};
