import { Button, SxProps, Typography, useTheme } from '@mui/material';
import { useThemeContext } from 'context/ThemeContext';

export const LabelButton = ({
  onClick,
  label,
  hasBorder = true,
  variant = 'outlined',
  sx,
}: {
  onClick?: () => void;
  label: string;
  variant?: 'outlined' | 'text' | 'contained' | undefined;
  hasBorder?: boolean;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const { isLightTheme } = useThemeContext();
  return (
    <Button
      onClick={onClick}
      variant={variant}
      sx={{
        borderRadius: 24,
        border: hasBorder ? `2px solid ${theme.palette.icon.primary}` : 'none',
        padding: '8px',
        minWidth: '0',
        backgroundColor: theme.palette.gradient.backgroundGradient,

        ...sx,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color:
            variant === 'contained' ? theme.palette.text.secondary : 'auto',
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};
