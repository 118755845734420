import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, IconButton } from '@mui/material';
import {
  useGetFilterOptions,
  useGetLocationsOptions,
} from 'api/hooks/useFilters';
import { getLabelByValue } from 'components/Filters/FiltersOptionsValues';
import { useFilterContext } from 'context/FilterContext';

export const FiltersChips = ({
  resetResults,
}: {
  resetResults: () => void;
}) => {
  const { generateRequestFiltersObj, removeFilterValue, getLabelByKey } =
    useFilterContext();

  const { locations } = useGetLocationsOptions();
  const { options } = useGetFilterOptions('marketplaces');
  const marketplaceOptions = options.map((option) => ({
    value: option.name,
    label: option.name,
  }));
  const districts = locations[0]?.regions[0]?.districts ?? [];
  const cities = districts.flatMap((district) => district.cities);
  const parishes = cities.flatMap((city) => city.parishes);

  const chipsConditions = generateRequestFiltersObj();
  const locationsConditions = chipsConditions.filter(
    (condition) =>
      condition.property_name === 'product__parish__city__district' ||
      condition.property_name === 'product__parish__city' ||
      condition.property_name === 'product__parish'
  );
  const parametersConditions = chipsConditions.filter(
    (condition) =>
      condition.property_name !== 'product__parish__city__district' &&
      condition.property_name !== 'product__parish__city' &&
      condition.property_name !== 'product__parish'
  );

  function getLocationValue(condition: {
    property_name: string;
    operator: string;
    value: string | number;
  }) {
    if (condition.property_name === 'product__parish__city__district') {
      return districts.find(
        (district) => district.id.toString() === condition.value
      )?.name;
    }
    if (condition.property_name === 'product__parish__city') {
      return cities.find((city) => city.id.toString() === condition.value)
        ?.name;
    }
    if (condition.property_name === 'product__parish') {
      return parishes.find((parish) => parish.id.toString() === condition.value)
        ?.name;
    }
  }
  const locationChip =
    locationsConditions.length > 0 ? (
      <Chip
        variant="outlined"
        key={locationsConditions[0].value}
        sx={{ margin: 1, cursor: 'pointer' }}
        label={
          locationsConditions.length > 1
            ? getLocationValue(locationsConditions[0]) +
              ' +' +
              (locationsConditions.length - 1)
            : (getLocationValue(locationsConditions[0]) ?? '')
        }
        deleteIcon={
          <IconButton>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        }
        onDelete={() => {
          removeFilterValue(locationsConditions[0]);
          resetResults();
        }}
      />
    ) : (
      <></>
    );

  return (
    <Box
      sx={{
        margin: 2,
        transition: 'margin 0.3s ease, padding 0.3s ease',
      }}
    >
      {locationChip}
      {parametersConditions.map((condition, index) => {
        const value = getLabelByValue(condition.value, marketplaceOptions);
        const label = getLabelByKey(condition);
        return (
          <Chip
            variant="outlined"
            key={condition.value}
            sx={{
              margin: 1,
              cursor: 'pointer',
              opacity: 0,
              transform: 'scale(0.9)',
              animation: `fadeIn 0.3s forwards`,
              animationDelay: `${index * 0.1}s`,
              '@keyframes fadeIn': {
                to: {
                  opacity: 1,
                  transform: 'scale(1)',
                },
              },
            }}
            label={label ? label + ' ' + value : value}
            deleteIcon={
              <IconButton>
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
            }
            onDelete={() => removeFilterValue(condition)}
          />
        );
      })}
    </Box>
  );
};
