import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { Location } from 'components/general/CustomAutoComplete/CustomAutoComplete';
import { useSnackbar } from 'context/SnackbarContext';
import fetchClient from '../rest/fetch-client';

// MediaItem interface
export interface MediaItem {
  media_file?: File | string | null; // Adjusted to include File type
  external_url: string;
  media_type: string;
  media_desc: string;
}

export type PropertyDetails = {
  parish_name?: string | null;
  id?: number;
  property_type?: string;
  sq_meters?: number;
  condition?: string;
  bedrooms?: number;
  bathrooms?: number;
  floors?: number;
  garage?: number | null;
  basement?: number | null;
  garden?: boolean;
  terrace?: number | null;
  balcony?: boolean;
  parking?: number | null;
  swimming_pool?: boolean;
  kitchen_type?: string | null;
  heating_type?: string | null;
  air_conditioning?: boolean | null;
  flooring_material?: string;
  energy_rating?: string;
  latitude?: number | null;
  longitude?: number | null;
  parish?: number | null;
};

export type Listing = {
  marketplace_name?: string;
  id: number;
  title: string;
  description?: string;
  listing_type?: string;
  created_at?: string;
  updated_at?: string;
  media: MediaItem[];
  publisher?: string;
  publisher_contact?: string;
  seller_type?: string;
  product: PropertyDetails;
  url?: string;
  price: string;
  currency: string;
  price_flexibility: string;
  marketplace_cfg?: string | number | null;
  added_by?: string | null;
};

export type ListingApiResponse = {
  results: Listing[];
  count: number;
  next: string;
  previous: string | null;
};

export type ListingFromApi = {
  product: PropertyDetails;
  url: string;
  price: string;
  currency: string;
  price_flexibility: string;
  marketplace_cfg: string;
};

type ListingApiRequestVariables = {
  isBackOffice: boolean;
  query: string;
  orderBy: string;
  updatedAt: string;
  page: number;
  pageSize?: number;
  locations?: Location[];
  condition?: string[];
  propertyType?: string[];
  listing_type?: string[];
  marketplaces?: string[];
  minPrice?: number;
  maxPrice?: number;
  minSize?: number;
  maxSize?: number;
  bedrooms?: number;
  amenities?: string[];
  bathrooms?: number;
};

const mapVariablesToParams = (
  variables: ListingApiRequestVariables
): URLSearchParams => {
  const params = new URLSearchParams();

  params.append('is_weird', variables.isBackOffice ? 'True' : 'False');
  params.append('page', variables.page.toString());
  params.append('order_by', variables.orderBy);
  if (variables.pageSize) {
    params.append('page_size', variables.pageSize.toString());
  }

  if (variables.locations && variables.locations.length > 0) {
    const depth0Locations = variables.locations.filter(
      (location) => location.depth === 0
    );
    let depth1Locations = variables.locations.filter(
      (location) => location.depth === 1
    );
    let depth2Locations = variables.locations.filter(
      (location) => location.depth === 2
    );

    depth0Locations.forEach((location) => {
      params.append('product__parish__city__district', location.id.toString());
      depth1Locations.filter((location) => location.parentId === location.id);
    });

    depth1Locations.forEach((location) => {
      params.append('product__parish__city', location.id.toString());
      depth2Locations.filter((location) => location.parentId === location.id);
    });
    depth2Locations.forEach((location) => {
      params.append('product__parish', location.id.toString());
    });
  }

  // Append conditions if provided
  if (variables.condition && variables.condition.length > 0) {
    variables.condition.forEach((c) => {
      params.append('product__condition', c);
    });
  }

  if (variables.amenities && variables.amenities.length > 0) {
    variables.amenities.forEach((c) => {
      params.append('product__' + c, 'True');
    });
  }

  // Append the first property type if provided
  if (variables.propertyType && variables.propertyType.length > 0) {
    params.append('product__property_type', variables.propertyType[0]);
  }

  // Append marketplaces if provided
  if (variables.listing_type && variables.listing_type.length > 0) {
    variables.listing_type.forEach((listing_type) => {
      params.append('listing_type', listing_type);
    });
  }

  // Append marketplaces if provided
  if (variables.marketplaces && variables.marketplaces.length > 0) {
    variables.marketplaces.forEach((marketplace) => {
      params.append('marketplace_cfg__marketplace__name', marketplace);
    });
  }

  // Append price and size filters
  if (variables.minPrice !== undefined) {
    params.append('price__gte', variables.minPrice.toString());
  }
  if (variables.maxPrice !== undefined) {
    params.append('price__lte', variables.maxPrice.toString());
  }
  if (variables.minSize !== undefined) {
    params.append('size__gte', variables.minSize.toString());
  }
  if (variables.maxSize !== undefined) {
    params.append('size__lte', variables.maxSize.toString());
  }
  if (variables.bedrooms !== undefined) {
    params.append('product__bedrooms', variables.bedrooms.toString());
  }
  if (variables.bathrooms !== undefined) {
    params.append('product__bathrooms', variables.bathrooms.toString());
  }
  if (variables.updatedAt !== undefined) {
    let dateOffset: number;

    switch (variables.updatedAt) {
      case 'last-24-hours':
        dateOffset = 24 * 60 * 60 * 1000; // 24 hours
        break;
      case 'last-week':
        dateOffset = 7 * 24 * 60 * 60 * 1000; // 7 days
        break;
      case 'last-month':
        dateOffset = 30 * 24 * 60 * 60 * 1000; // Approx. 30 days
        break;
      default:
        dateOffset = 0;
    }

    if (dateOffset > 0) {
      const calculatedDate = new Date(Date.now() - dateOffset).toISOString();
      params.append('updated_at', calculatedDate);
    }
  }

  return params;
};

export function useListing(
  variables: ListingApiRequestVariables,
  options?: Omit<
    UseQueryOptions<ListingApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) {
  const { showMessage } = useSnackbar();
  const {
    data: leadsApiResponse,
    error,
    ...rest
  } = useQuery<ListingApiResponse, Error>({
    queryKey: ['listing', variables],
    queryFn: async () => {
      try {
        const response = await fetchClient.get(
          `${fetchClient.defaults.baseURL}/marketplace-listings/`,
          {
            params: mapVariablesToParams(variables),
          }
        );
        return response.data;
      } catch (err) {
        throw err; // rethrow to trigger `error` in useQuery
      }
    },
    ...options,
  });

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve posts matches',
      'error'
    );
    console.error('Error in useListing:', error); // log error state
  }

  return {
    results: leadsApiResponse?.results || [],
    totalCount: leadsApiResponse?.count || 0,
    nextPage: leadsApiResponse?.next || null,
    previousPage: leadsApiResponse?.previous || null,
    ...rest,
  };
}

export function useListingDetails(
  variables: {
    id: string;
  },
  options?: Omit<
    UseQueryOptions<ListingApiResponse, Error>,
    'queryKey' | 'queryFn'
  >
) {
  const { showMessage } = useSnackbar();
  const {
    data: leadsApiResponse,
    isLoading,
    error,
    ...rest
  } = useQuery<ListingApiResponse, Error>({
    queryKey: ['listingDetails'],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}/marketplace-listings/`,
        {
          params: {
            id: variables.id,
            format: 'json',
          },
        }
      );

      return response.data;
    },
    ...options,
  });

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve post details',
      'error'
    );
    console.error('Error in useListing:', error); // log error state
  }

  return {
    listingDetails: leadsApiResponse?.results[0],
    isLoading,
    ...rest,
  };
}

export const useAddListing = () => {
  const { showMessage } = useSnackbar();
  const { mutate: addListing, ...rest } = useMutation({
    mutationFn: (request: Listing) => {
      const formData = new FormData();

      // Append primitive fields
      formData.append('title', request.title);
      formData.append('description', request.description || '');
      formData.append('marketplace_name', request.marketplace_name || '');
      formData.append('price', request.price);
      formData.append('currency', request.currency);
      formData.append('price_flexibility', request.price_flexibility);
      formData.append('url', request.url || '');

      // Convert and append product details as a JSON string
      formData.append('product', JSON.stringify(request.product));

      // Append media items
      request.media.forEach((mediaItem, index) => {
        if (mediaItem.media_file) {
          formData.append(`media_file_${index}`, mediaItem.media_file);
        }
      });

      // Make the request using the form data
      return fetchClient.post(
        `${fetchClient.defaults.baseURL}/create-listing/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log('Listing added successfully:', data);
    },
    onError: (data) => {
      showMessage('Something went wrong when trying to add a post', 'error');
    },
  });

  return { addListing, ...rest };
};

export const useDeleteListing = (resetResults?: () => void) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: deleteListing, ...rest } = useMutation({
    mutationFn: (id: string) => {
      return fetchClient.delete(
        `${fetchClient.defaults.baseURL}/marketplace-listings/${id}/`,
        {
          params: {
            id: id,
            format: 'json',
          },
        }
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['listing'] });
      console.log('Listing deleted successfully:', data);
      if (resetResults) {
        resetResults();
      }
    },
    onError: (data) => {
      showMessage('Something went wrong when trying to delete a post', 'error');
    },
  });

  return { deleteListing, ...rest };
};

// Fire-and-forget function for posting stats
export function postStatsWithoutResponse(page_id: string) {
  fetchClient
    .post(
      `${fetchClient.defaults.baseURL}/page-view/`,
      new URLSearchParams({ page_id: page_id }).toString(), // Send `page_id` as form data
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .catch((error) => {
      console.error('Failed to post stats:', error);
    });
}
