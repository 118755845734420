import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { darkTheme, lightTheme } from '../theme/theme';

interface ThemeContextProps {
  toggleTheme: () => void;
  themeMode: 'light' | 'dark';
  isLightTheme: boolean;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context)
    throw new Error('useThemeContext must be used within ThemeProvider');
  return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('themeMode');
    return savedTheme === 'light' || savedTheme === 'dark'
      ? savedTheme
      : 'light';
  });

  const toggleTheme = () => {
    setThemeMode((prev) => {
      const newTheme = prev === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newTheme);
      return newTheme;
    });
  };

  useEffect(() => {
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  const isLightTheme = themeMode === 'light';

  return (
    <ThemeContext.Provider value={{ toggleTheme, themeMode, isLightTheme }}>
      <MuiThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
