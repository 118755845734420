import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { FilterProvider } from 'context/FilterContext';
import { useTranslation } from 'context/TranslationContext';
import ConfigurationSection from 'pages/Profile/ConfigurationsSection/ConfigurationsSection';
import PersonalInfo from 'pages/Profile/PersonalInfo';
import { SettingsSection } from 'pages/Profile/SettingsSection/SettingsSection';
import { useState } from 'react';

const ProfilePage = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  // State for managing active tab
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr', md: '1fr 2fr' }}
      gap={2}
      padding={3}
    >
      <PersonalInfo />
      <Box sx={{ marginTop: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="profile tabs"
          sx={{ borderBottom: `1px solid ${theme.palette.border.primary}` }}
        >
          <Tab
            label={t('profile.filter_configurations')}
            sx={{
              color:
                activeTab === 0
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
              textTransform: 'none', // Prevent text from being in caps
            }}
          />
          <Tab
            label={t('profile.settings')}
            sx={{
              color:
                activeTab === 1
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
              textTransform: 'none', // Prevent text from being in caps
            }}
          />
        </Tabs>

        {/* Conditional rendering based on active tab */}
        {activeTab === 0 && (
          <FilterProvider>
            <ConfigurationSection />
          </FilterProvider>
        )}
        {activeTab === 1 && <SettingsSection />}
      </Box>
    </Box>
  );
};

export default ProfilePage;
