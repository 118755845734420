import BedIcon from '@mui/icons-material/Bed';
import GradeIcon from '@mui/icons-material/Grade';
import StraightenIcon from '@mui/icons-material/Straighten';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useListingDetails } from 'api/hooks/useListing';
import { Notification, useNotifications } from 'api/hooks/useNotification';
import { OptionalGradientContainer } from 'components/general/GradientContainer/GradientContainer';
import IconLabel from 'components/general/IconLabel/IconLabel';
import { useThemeContext } from 'context/ThemeContext';
import { Media } from 'pages/ListingDetails/page-components/PropertyMedia/PropertyMedia';
import { useState } from 'react';

import ChurchIcon from '@mui/icons-material/Church';
import { alpha } from '@mui/material/styles';
import { DeleteIcon } from 'components/animatedIcons/delete';
import { GripIcon } from 'components/animatedIcons/grip';
import { useTranslation } from 'context/TranslationContext';

const getRelativeTime = (dateString: string | undefined): string => {
  if (!dateString) return 'No Date';

  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now.getTime() - date.getTime();

  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) return `${seconds}s`;
  if (minutes < 60) return `${minutes}m`;
  if (hours < 24) return `${hours}h`;
  return `${days}d`;
};
export const Notifications = () => {
  const theme = useTheme();
  const { isLightTheme } = useThemeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { notifications, notificationRead } = useNotifications();

  const [selectedNotification, setSelectedNotification] = useState<
    Notification | undefined
  >(undefined);

  const handleSelectNotification = (notification: Notification) => {
    if (selectedNotification !== undefined) {
      setSelectedNotification(undefined);
    } else {
      notificationRead({ id: notification.id, read: true });
      setSelectedNotification(notification);
    }
  };

  const boxShadow = !isLightTheme
    ? `-6px -1px 6px 0px #24504f `
    : `-6px -1px 6px 0px #c0cbd3 `;
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        position: 'relative', // Maintain relative positioning
        overflow: 'hidden',
      }}
    >
      {/* Notifications Inbox */}
      <Box
        onClick={() =>
          !!selectedNotification && setSelectedNotification(undefined)
        }
        sx={{
          flex: 1,
          zIndex: 1,
        }}
      >
        <NotificationsInbox
          notifications={notifications as Notification[]}
          handleSelectNotification={handleSelectNotification}
          selectedNotification={selectedNotification}
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: selectedNotification ? 0 : '-50%',
          width: isMobile ? '80%' : '50%',
          height: '100%',
          zIndex: 2,
          overflow: 'hidden',
          boxShadow: boxShadow,
          backgroundColor: 'white',
          transition: 'right 1s ease',
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
        }}
      >
        {selectedNotification && (
          <NotificationDetails notification={selectedNotification} />
        )}
      </Box>
    </Box>
  );
};

export const NotificationsInbox = ({
  notifications,
  handleSelectNotification,
  selectedNotification,
}: {
  notifications: Notification[];
  handleSelectNotification: (notification: Notification) => void;
  selectedNotification?: Notification;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '50%' }}>
      <Typography
        variant="body2" // Changed to match the importance of a main phrase
        sx={{
          position: 'absolute',
          top: '50%',
          left: '75%',
          transform: 'translate(-50%, -50%)',
          color: 'text.disabled',
          opacity: 0.3, // Faded background effect
          fontSize: '3rem', // Adjusted for a balanced display
          textAlign: 'center',
          pointerEvents: 'none', // Prevents interaction
        }}
      >
        {t('notifications.your_hub')}
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '1rem',
            opacity: 0.6, // Slightly more visible than the main phrase
            mt: 1, // Adds spacing between the two phrases
          }}
        >
          {t('notifications.your_hub_subtitle')}
        </Typography>
      </Typography>
      {notifications.length > 0 ? (
        <Stack
          sx={{
            margin: '16px',
            borderRadius: '16px',
            height: 'calc(100vh - 32px)',
            overflowY: 'auto',
          }}
        >
          {notifications.map((notification: Notification, index: number) => {
            return (
              <NotificationRow
                notification={notification}
                index={index}
                selectedNotification={selectedNotification}
                handleSelectNotification={handleSelectNotification}
                notificationsLength={notifications.length}
              />
            );
          })}
        </Stack>
      ) : (
        <Typography variant="h6" color="textSecondary" textAlign="center">
          {t('notifications.no_messages')}
        </Typography>
      )}
      <Typography variant="h6" textAlign="center" sx={{ opacity: 0.3, mt: 20 }}>
        {t('notifications.end_inbox')}
      </Typography>
    </Box>
  );
};

export const NotificationRow = ({
  notification,
  selectedNotification,
  handleSelectNotification,
  notificationsLength,
  index,
}: {
  notification: Notification;
  selectedNotification?: Notification;
  handleSelectNotification: (notification: Notification) => void;
  notificationsLength: number;
  index: number;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isNotificationDetailsOpen =
    selectedNotification && selectedNotification.id === notification.id;
  const isNotificationRead = notification.read;
  return (
    <Stack
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => {
        handleSelectNotification(notification);
        e.stopPropagation();
      }}
      key={index}
      sx={{
        width: '-webkit-fill-available',
        borderTopLeftRadius: index === 0 ? '16px' : '0px',
        borderTopRightRadius: index === 0 ? '16px' : '0px',
        borderBottomLeftRadius:
          index === notificationsLength - 1 ? '16px' : '0px',
        borderBottomRightRadius:
          index === notificationsLength - 1 ? '16px' : '0px',
        cursor: 'pointer',
        padding: 2,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.2),
        },
        backgroundColor: isNotificationRead
          ? 'auto'
          : isNotificationDetailsOpen
            ? alpha(theme.palette.primary.main, 0.2)
            : alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.main,
              width: 40,
              height: 40,
              fontSize: '12px',
            }}
          >
            RH
          </Avatar>
        </Box>
        <Stack>
          <Typography variant="h6">
            {notification.title || 'No Title'}
          </Typography>
          <Typography variant="subtitle1">{notification.body || ''}</Typography>
        </Stack>
        <Typography variant="subtitle1" sx={{ marginLeft: 'auto' }}>
          {getRelativeTime(notification.created_at)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <GripIcon />
          </IconButton>
          <IconButton
            sx={{ bottom: 2 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Stack>
  );
};

export const NotificationDetails = ({
  notification,
}: {
  notification: Notification;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isListingAlert = !!notification.extras.listing_id;
  const { listingDetails } = useListingDetails(
    { id: notification.extras.listing_id },
    { enabled: isListingAlert }
  );

  if (!listingDetails) {
    return <CircularProgress color="inherit" />;
  }

  const { product } = listingDetails;
  const detailsContainer = [
    {
      icon: <StraightenIcon sx={{ color: theme.palette.icon.secondary }} />,
      value: product.sq_meters
        ? `${Math.trunc(product.sq_meters)} m²`
        : t('notifications_alert_property.no_size'),
      label: t('notifications_alert_property.meters'),
    },
    {
      icon: <GradeIcon sx={{ color: theme.palette.icon.secondary }} />,
      value: product.condition,
      label: t('notifications_alert_property.condition'),
    },
    {
      icon: <ChurchIcon sx={{ color: theme.palette.icon.secondary }} />,
      value: product.parish,
      label: t('notifications_alert_property.parish'),
    },
    {
      icon: <BedIcon sx={{ color: theme.palette.icon.secondary }} />,
      value: product.bedrooms,
      label: t('notifications_alert_property.bedrooms'),
    },
  ];

  const detailsCard = (
    <OptionalGradientContainer
      sx={{
        boxSizing: 'border-box',
        borderRadius: '0px',
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
        borderRight: 'auto',
        height: 'fit-content',
      }}
    >
      <Stack sx={{ marginY: 2, marginX: 3, gap: 1 }}>
        {detailsContainer.map((item, idx) => (
          <IconLabel
            key={idx}
            icon={item.icon}
            value={item.value}
            label={item.label}
          />
        ))}
      </Stack>
    </OptionalGradientContainer>
  );

  return (
    <Stack
      sx={{
        height: '100vh',
        width: '100%',
      }}
    >
      <Stack m={4}>
        {listingDetails && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Media
              openOneSwipper={true}
              images={listingDetails.media}
              sx={{ flex: 1, margin: 2 }}
            />
            <Stack sx={{ gap: 2, width: '100%' }}>
              <Typography>{listingDetails.title}</Typography>
              {detailsCard}
            </Stack>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
