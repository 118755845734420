import {
  Box,
  Button,
  Fade,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactComponent as Realhive } from 'components/icons/realhive.svg';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'context/TranslationContext';
import Background from 'pages/Login/bubbles';
import RecoverPasswordForm from 'pages/Login/RecoverPasswordForm';
import RegisterForm from 'pages/Login/RegisterForm';
import ResetPasswordForm from 'pages/Login/ResetPasswordForm';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import LoginForm from './LoginForm';

const Login = () => {
  const { id: user_id, token: user_token } = useParams<{
    id: string;
    token: string;
  }>();
  const isResetPassword = !!user_id && !!user_token;

  const [isRegistering, setIsRegistering] = useState(false);
  const [isRecovering, setIsRecovering] = useState(false);
  const [rightSideWidth, setRightSideWidth] = useState('0%');
  const theme = useTheme();
  const navigate = useNavigate();
  const { loginGoogle } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {} = useAuth();
  const { t } = useTranslation();
  console.log(t('login.button.create_account'));
  console.log(t('login.button.login_existing'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setRightSideWidth(isMobile ? '100%' : '50%'); // Change to 100% on mobile after 5 seconds
    }, 1500);

    return () => clearTimeout(timer);
  }, [isMobile]);

  const resetForm = isResetPassword ? (
    <ResetPasswordForm user_id={user_id} user_token={user_token} />
  ) : (
    <></>
  );
  const registerForm = <RegisterForm />;
  const recoverForm = <RecoverPasswordForm />;
  const loginForm = (
    <LoginForm handleIsRecovering={() => setIsRecovering(true)} />
  );
  const formToRender = isResetPassword
    ? resetForm
    : isRegistering
      ? registerForm
      : isRecovering
        ? recoverForm
        : loginForm;

  return (
    <Box
      display="flex"
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        backgroundColor: '#1E3756',
      }}
    >
      <Background
        id="bubbleCanvas"
        sx={{ width: '100%', height: 'auto' }}
        color="255,0,0"
        children={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={5}
            sx={{
              backgroundColor: '#1E3756',
              textAlign: 'center',
              width: isMobile ? '100%' : '50%',
              transition: 'width 1s ease',
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ width: '80%' }}
            >
              <Realhive style={{ width: '100%', height: 'auto' }} />
              <TypeAnimation
                sequence={[
                  t('login.animation.insight'),
                  1000,
                  t('login.animation.productivity'),
                  1000,
                  t('login.animation.workflow'),
                  1000,
                  t('login.animation.trends'),
                  1000,
                ]}
                speed={60}
                repeat={Infinity}
                style={{
                  fontSize: '18px',
                  color: '#22e1dc',
                  width: '80%',
                  marginTop: '32px',
                  height: '50px',
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 600,
                }}
              />
            </Stack>
          </Box>
        }
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          right: 0,
          width: rightSideWidth, // Start at 0% and expand
          height: '100%',
          backgroundColor: theme.palette.background.default,
          borderRadius: isMobile ? '0' : '80px 0 0 80px',
          transition: 'width 2s ease',
        }}
      >
        <Fade
          in={rightSideWidth === '100%' || rightSideWidth === '50%'}
          timeout={isMobile ? 9000 : 4000}
        >
          <Stack
            sx={{ minWidth: isMobile ? 'unset' : '350px' }}
            alignItems="center"
          >
            {formToRender}

            <Button
              onClick={() => {
                setIsRecovering(false);
                setIsRegistering((prev) => !prev);
              }}
              variant="outlined"
              fullWidth
              sx={{
                paddingX: '32px',
                color: '#1E3756',
                borderRadius: 36,
                fontWeight: 'bold',
                height: '48px',
                maxWidth: '500px',
              }}
            >
              {isRegistering
                ? t('login.button.login_existing')
                : t('login.button.create_account')}
            </Button>
          </Stack>
        </Fade>
      </Box>
    </Box>
  );
};

export default Login;
