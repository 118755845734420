import { Location } from 'components/general/CustomAutoComplete/CustomAutoComplete';
import React, { createContext, useContext, useState } from 'react';

interface FilterContextProps {
  query: string;
  setQuery: (query: string) => void;
  orderBy: string;
  setOrderBy: (condition: string) => void;
  locations: Location[];
  setLocations: (locations: Location[]) => void;
  updatedAt: string;
  setUpdatedAt: (updatedAt: string) => void;
  condition: string[];
  setCondition: (condition: string[]) => void;
  propertyType: string[];
  setPropertyType: (propertyType: string[]) => void; // Corrected parameter name
  minPrice?: number;
  listing_type: string[];
  setListingType: (listing_type: string[]) => void;
  setMinPrice: (price?: number) => void;
  maxPrice?: number;
  setMaxPrice: (price?: number) => void;
  minSize?: number;
  setMinSize: (size?: number) => void;
  maxSize?: number;
  setMaxSize: (size?: number) => void;
  bedroomFilter?: number;
  setBedroomFilter: (bedrooms?: number) => void;
  bathroomFilter?: number;
  setBathroomFilter: (bathrooms?: number) => void;
  amenities: string[];
  setAmenities: (amenities: string[]) => void;
  marketplaces: string[];
  setMarketplaces: (marketplaces: string[]) => void;
  resetFilters: () => void;
  generateRequestFiltersObj: () => {
    property_name: string;
    operator: string;
    value: string | number;
  }[];
  populateFiltersFromObj: (
    filters: {
      value: string | number;
      property_name: string;
      operator: string;
    }[]
  ) => void;
  removeFilterValue: (filters: {
    value: string | number;
    property_name: string;
    operator: string;
  }) => void;
  getLabelByKey: (filters: {
    value: string | number;
    property_name: string;
    operator: string;
  }) => string | undefined;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState<string>('created_at');
  const [updatedAt, setUpdatedAt] = useState<string>('indifferent');
  const [condition, setCondition] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [listing_type, setListingType] = useState<string[]>([]);
  const [propertyType, setPropertyType] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const [minSize, setMinSize] = useState<number>();
  const [maxSize, setMaxSize] = useState<number>();
  const [bedroomFilter, setBedroomFilter] = useState<number>();
  const [bathroomFilter, setBathroomFilter] = useState<number>();
  const [marketplaces, setMarketplaces] = useState<string[]>([]);
  const [amenities, setAmenities] = useState<string[]>([]);

  const resetFilters = () => {
    setQuery('');
    setUpdatedAt('indifferent');
    setCondition([]);
    setLocations([]);
    setListingType([]);
    setPropertyType([]);
    setMinPrice(undefined);
    setMaxPrice(undefined);
    setMinSize(undefined);
    setMaxSize(undefined);
    setBedroomFilter(undefined);
    setBathroomFilter(undefined);
    setMarketplaces([]);
    setAmenities([]);
  };

  const generateRequestFiltersObj = () => {
    const filters = [];

    // Price filters
    if (minPrice !== undefined)
      filters.push({
        property_name: 'price',
        operator: '>',
        value: Number(minPrice),
      });
    if (maxPrice !== undefined)
      filters.push({
        property_name: 'price',
        operator: '<',
        value: Number(maxPrice),
      });

    // Size filters
    if (minSize !== undefined)
      filters.push({
        property_name: 'product__sq_meters',
        operator: '>',
        value: Number(minSize),
      });
    if (maxSize !== undefined)
      filters.push({
        property_name: 'product__sq_meters',
        operator: '<',
        value: Number(maxSize),
      });

    // Bedroom and bathroom filters
    if (bedroomFilter)
      filters.push({
        property_name: 'listing__bedrooms',
        operator: '=',
        value: Number(bedroomFilter),
      });
    if (bathroomFilter)
      filters.push({
        property_name: 'listing__bathrooms',
        operator: '=',
        value: Number(bathroomFilter),
      });

    marketplaces.forEach((marketplace) => {
      filters.push({
        property_name: 'marketplace_cfg__marketplace',
        operator: 'contains',
        value: marketplace,
      });
    });

    // Amenities filter - assumes each amenity is a separate filter
    amenities.forEach((amenity) => {
      filters.push({
        property_name: 'amenities',
        operator: 'contains',
        value: amenity,
      });
    });

    // Query filter
    if (query)
      filters.push({
        property_name: 'query',
        operator: 'contains',
        value: query,
      });

    // condition filter - assumes each condition option is a separate filter
    condition.forEach((condition) => {
      filters.push({
        property_name: 'product__condition',
        operator: '=',
        value: condition,
      });
    });

    propertyType.forEach((propertyType) => {
      filters.push({
        property_name: 'product__property_type', // Corrected property name
        operator: '=',
        value: propertyType,
      });
    });

    listing_type.forEach((listing_type) => {
      filters.push({
        property_name: 'listing_type',
        operator: '=',
        value: listing_type,
      });
    });

    if (locations.length > 0) {
      const depth0Locations = locations.filter(
        (location) => location.depth === 0
      );
      let depth1Locations = locations.filter(
        (location) => location.depth === 1
      );
      let depth2Locations = locations.filter(
        (location) => location.depth === 2
      );

      depth0Locations.forEach((location) => {
        filters.push({
          property_name: 'product__parish__city__district',
          operator: '=',
          value: location.id.toString(),
        });

        depth1Locations.filter((location) => location.parentId === location.id);
      });

      depth1Locations.forEach((location) => {
        filters.push({
          property_name: 'product__parish__city',
          operator: '=',
          value: location.id.toString(),
        });

        depth2Locations.filter((location) => location.parentId === location.id);
      });
      depth2Locations.forEach((location) => {
        filters.push({
          property_name: 'product__parish',
          operator: '=',
          value: location.id.toString(),
        });
      });
    }

    return filters;
  };

  const populateFiltersFromObj = (
    filters: {
      value: string | number;
      property_name: string;
      operator: string;
    }[]
  ) => {
    filters.forEach((filter) => {
      const { property_name, value } = filter;

      switch (property_name) {
        case 'price':
          if (filter.operator === '>') setMinPrice(Number(value));
          if (filter.operator === '<') setMaxPrice(Number(value));
          break;

        case 'product__sq_meters':
          if (filter.operator === '>') setMinSize(Number(value));
          if (filter.operator === '<') setMaxSize(Number(value));
          break;

        case 'product__bedrooms':
          setBedroomFilter(Number(value));
          break;

        case 'product__bathrooms':
          setBathroomFilter(Number(value));
          break;

        case 'marketplace_cfg__marketplace':
          if (typeof value === 'string' && !marketplaces.includes(value)) {
            setMarketplaces((prev) => [...prev, value]);
          }
          break;

        case 'amenities':
          if (typeof value === 'string' && !amenities.includes(value)) {
            setAmenities((prev) => [...prev, value]);
          }
          break;

        case 'query':
          setQuery(value as string);
          break;

        case 'product__condition':
          if (typeof value === 'string' && !condition.includes(value)) {
            setCondition((prev) => [...prev, value]);
          }
          break;

        case 'product__property_type':
          if (typeof value === 'string' && !propertyType.includes(value)) {
            setPropertyType((prev) => [...prev, value]);
          }
          break;

        case 'listing_type':
          if (typeof value === 'string' && !listing_type.includes(value)) {
            setListingType((prev) => [...prev, value]);
          }
          break;

        default:
          console.warn(`Unhandled property_name: ${property_name}`);
      }
    });
  };

  const removeFilterValue = (filter: {
    value: string | number;
    property_name: string;
    operator: string;
  }) => {
    const { property_name, value } = filter;

    switch (property_name) {
      case 'product__parish__city__district':
      case 'product__parish__city':
      case 'product__parish':
        setLocations([]);
        break;

      case 'price':
        if (filter.operator === '>') setMinPrice(undefined); // or set to a default value
        if (filter.operator === '<') setMaxPrice(undefined); // or set to a default value
        break;

      case 'product__sq_meters':
        if (filter.operator === '>') setMinSize(undefined); // or set to a default value
        if (filter.operator === '<') setMaxSize(undefined); // or set to a default value
        break;

      case 'product__bedrooms':
        setBedroomFilter(undefined); // or set to a default value
        break;

      case 'product__bathrooms':
        setBathroomFilter(undefined); // or set to a default value
        break;

      case 'marketplace_cfg__marketplace':
        if (typeof value === 'string') {
          setMarketplaces((prev) => prev.filter((item) => item !== value));
        }
        break;

      case 'amenities':
        if (typeof value === 'string') {
          setAmenities((prev) => prev.filter((item) => item !== value));
        }
        break;

      case 'query':
        setQuery(''); // or set to a default value
        break;

      case 'product__condition':
        if (typeof value === 'string') {
          setCondition((prev) => prev.filter((item) => item !== value));
        }
        break;

      case 'product__property_type':
        if (typeof value === 'string') {
          setPropertyType((prev) => prev.filter((item) => item !== value));
        }
        break;

      case 'listing_type':
        if (typeof value === 'string') {
          setListingType((prev) => prev.filter((item) => item !== value));
        }
        break;

      default:
        console.warn(`Unhandled property_name: ${property_name}`);
    }
  };

  const getLabelByKey = (filter: {
    value: string | number;
    property_name: string;
    operator: string;
  }) => {
    const { property_name, value } = filter;
    switch (property_name) {
      case 'price':
        if (filter.operator === '>') return '>=';

        if (filter.operator === '<') return '<=';

      case 'product__sq_meters':
        if (filter.operator === '>') return 'size >=';
        if (filter.operator === '<') return 'size <=';
        break;

      case 'product__bedrooms':
        return 'Nº bedrooms';

      case 'product__bathrooms':
        return 'Nº bathrooms';

      default:
        return undefined;
    }
  };

  return (
    <FilterContext.Provider
      value={{
        query,
        setQuery,
        updatedAt,
        setUpdatedAt,
        orderBy,
        setOrderBy,
        locations,
        setLocations,
        condition,
        setCondition,
        listing_type,
        setListingType,
        propertyType,
        setPropertyType,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        minSize,
        setMinSize,
        maxSize,
        setMaxSize,
        bedroomFilter,
        setBedroomFilter,
        bathroomFilter,
        setBathroomFilter,
        marketplaces,
        setMarketplaces,
        amenities,
        setAmenities,
        resetFilters,
        generateRequestFiltersObj,
        populateFiltersFromObj,
        removeFilterValue,
        getLabelByKey,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};
