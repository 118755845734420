import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useSnackbar } from 'context/SnackbarContext';
import fetchClient, { post } from '../rest/fetch-client';

type ReportRequest = {
  listing: number;
  reason: string;
  report?: string;
};

type ReportResponse = {
  listing: number;
  reason: string;
  report?: string;
};

export const useCreateReport = () => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();
  const { mutate: createReport, ...rest } = useMutation({
    mutationFn: async (request: ReportRequest) => {
      const response = await post(
        `${fetchClient.defaults.baseURL}listing-reports/`,
        request
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reports'] });
      showMessage('Post was successfully reported', 'success');
    },
    onError: () => {
      showMessage(
        'Something went wrong when trying to report the post',
        'error'
      );
    },
  });

  return { createReport, ...rest };
};

export function useGetReports(
  variables: { id?: number },
  options?: Omit<UseQueryOptions<ReportResponse, Error>, 'queryKey' | 'queryFn'>
) {
  const { showMessage } = useSnackbar();
  const { data, isLoading, error, ...rest } = useQuery<ReportResponse, Error>({
    queryKey: ['reports'],
    queryFn: async () => {
      const response = await fetchClient.get(
        `${fetchClient.defaults.baseURL}listing-reports/`,
        {
          params: {
            listing: variables.id,
          },
        }
      );

      return response.data;
    },
  });

  if (error) {
    showMessage(
      'Something went wrong when trying to retrieve post details',
      'error'
    );
    console.error('Error in useListing:', error); // log error state
  }

  return {
    reports: data,
    isLoading,
    ...rest,
  };
}
